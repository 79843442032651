import axios from "axios";
import { showError } from "../App";
import { jwtDecode } from "jwt-decode";
import config from "../config";
export const API_BASE_URL = (process.env.REACT_APP_API_BASE_URL || "") + "/api";
// axios.defaults.withCredentials = true;
const customFetch = axios.create({
	baseURL: API_BASE_URL,
	// headers: {
	// 	"Content-type": "application/json",
	// },
	// withCredentials: true,
});

export const addTokenToLocalStorage = (access_token) => {
	localStorage.setItem("token", access_token);
	const event = new Event("storage");
	// Dispatch the event
	window.dispatchEvent(event);
};

export const isTokenValid = () => {
	const token = localStorage.getItem("token");
	if (token) {
		const decoded_token = jwtDecode(token);
		if (decoded_token) {
			// JWT exp is in seconds
			if (decoded_token.exp * 1000 > new Date().getTime()) {
				return true;
			}
		}
	}
	return false;
};

export const getTokenFromLocalStorage = () => {
	const token = localStorage.getItem("token");
	if (token) {
		const decoded_token = jwtDecode(token);
		if (decoded_token) {
			// JWT exp is in seconds
			if (decoded_token.exp * 1000 > new Date().getTime()) {
				return token;
			}
		}
	}
	logout();
	return null;
};

const logout = () => {
	console.log("Redirect to login");
	// showError("Session expired. Please login again.");
	removeTokenFromLocalStorage();
	window.location.href = config.logoutRedirect;
};

export const removeTokenFromLocalStorage = () => {
	localStorage.removeItem("token");
	const event = new Event("storage");
	// Dispatch the event
	window.dispatchEvent(event);
};

customFetch.interceptors.request.use(
	(config) => {
		const token = getTokenFromLocalStorage();
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	(error) => Promise.reject(error)
);

customFetch.interceptors.response.use(
	(response) => response,
	async (error) => {
		console.log(error);
		if (error.response.status === 401) {
			logout();
		}

		return Promise.reject(error);
	}
);

export default class Api {
	/**
	 *
	 * @param {String} url
	 * @returns {Object} Either { success: true, data: string } or { success: false, error: string }
	 */
	get = async (url, query) => {
		console.log("GET: ", url);
		try {
			const res = await customFetch.get(url, { params: query });

			return {
				success: true,
				data: res.data,
				time: new Date(res.headers.date),
			};
		} catch (err) {
			// console.log("Error: ", err);
			if (err.hasOwnProperty("response")) {
				return {
					message: err.response.data,
					status: err.response.status,
				};
			} else {
				return {
					success: false,
					error: "Can't connect to server",
				};
			}
		}
	};

	/**
	 *
	 * @param {String} url
	 * @param {Object} body
	 * @returns {Object} Either { success: true, data: string } or { success: false, error: string }
	 */
	post = async (url, body) => {
		console.log("POST: ", url, body);
		try {
			const res = await customFetch.post(url, body);
			// console.log("POST RES: ", res);
			return {
				success: true,
				data: res.data,
				time: new Date(res.headers.date),
			};
		} catch (err) {
			if (err.hasOwnProperty("response")) {
				return {
					message: err.response.data,
					status: err.response.status,
				};
			} else {
				return {
					success: false,
					error: "Can't connect to server",
				};
			}
		}
	};

	/**
	 *
	 * @param {String} url
	 * @param {Object} body
	 * @returns {Object} Either { success: true, data: string } or { success: false, error: string }
	 */
	put = async (url, body) => {
		try {
			const res = await customFetch.put(url, body);
			return {
				success: true,
				data: res.data,
				time: new Date(res.headers.date),
			};
		} catch (err) {
			console.log(err.response);
			if (err.hasOwnProperty("response")) {
				return {
					message: err.response.data,
					status: err.response.status,
				};
			} else {
				return {
					success: false,
					error: "Can't connect to server",
				};
			}
		}
	};

	/**
	 *
	 * @param {String} url
	 * @returns {Object} Either { success: true, data: string } or { success: false, error: string }
	 */
	delete = async (url) => {
		try {
			const res = await customFetch.delete(url);
			return {
				success: true,
				data: res.data,
				time: new Date(res.headers.date),
			};
		} catch (err) {
			if (err.hasOwnProperty("response")) {
				return {
					message: err.response.data,
					status: err.response.status,
				};
			} else {
				return {
					success: false,
					error: "Can't connect to server",
				};
			}
		}
	};
}
