import { Box } from "@mui/material";
import config from "../../config";
import ProfileTable from "./ProfileTable";

export default function Profiles() {
	return (
		<Box className="p-5 mx-auto flex items-center flex-col gap-10 w-full">
			<ProfileTable />
		</Box>
	);
}
