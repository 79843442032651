import {
	Box,
	Card,
	Checkbox,
	Divider,
	FormControl,
	InputAdornment,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import config from "../../config";
import { Clear, Send, Upload } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import profileApi from "../../api/profile";
import { showSuccess } from "../../App";
import notificationApi from "../../api/notification";

export default function Notification() {
	const [loading, setLoading] = useState(false);
	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		const res = await notificationApi.sendNotification(data);
		if (res.success) {
			// setData({
			// 	title: "",
			// 	body: "",
			// 	user_ids: [],
			// });
			showSuccess("Notification sent successfully");
		}
		setLoading(false);
	};
	const [data, setData] = useState({
		title: "",
		body: "",
		user_ids: [],
	});
	const [selectAll, setSelectAll] = useState(false);
	const [users, setUsers] = useState([]);

	const fetchUsers = async () => {
		const res = await profileApi.getPriyoPayAppUsers();
		if (res.success) {
			setUsers(res.data);
		}
	};

	useEffect(() => {
		fetchUsers();
	}, []);

	return (
		<Box
			className="p-5 mx-auto flex items-center flex-col gap-10 justify-center w-full"
			sx={{
				minHeight: `calc(100vh - ${config.topbarHeight}px)`,
			}}
		>
			<Card className="w-full p-5 flex flex-col gap-3 md:w-[30rem] mx-auto">
				<Typography variant="h5">Send Push Notification</Typography>
				<Divider />
				<form onSubmit={handleSubmit} className="flex flex-col gap-5">
					<TextField
						type="name"
						value={data.title}
						onChange={(e) =>
							setData({ ...data, title: e.target.value })
						}
						label="Title"
						required
						autoComplete="off"
						disabled={loading}
					/>
					<TextField
						type="name"
						onChange={(e) =>
							setData({ ...data, body: e.target.value })
						}
						value={data.body}
						disabled={loading}
						label="Body"
						minRows={3}
						required
						multiline
					/>
					<FormControl
						fullWidth
						className="input-field"
						variant="outlined"
						required
						disabled={loading}
					>
						<InputLabel
							htmlFor="outlined-adornment"
							className="input-label"
						>
							Selected Users
						</InputLabel>
						<Select
							multiple
							className="w-full"
							value={data.user_ids}
							onChange={(e) => {
								setData({
									...data,
									user_ids: e.target.value,
								});
							}}
							input={<OutlinedInput label={"Selected Fields"} />}
							renderValue={(selected) =>
								selected
									.map((value) => {
										const user = users.find(
											(user) => user.id === value
										);
										return user ? user.email : value;
									})
									.join(", ")
							}
						>
							<MenuItem>
								<Checkbox
									checked={selectAll}
									indeterminate={
										data.user_ids.length > 0 &&
										data.user_ids.length < users.length
									}
									onChange={(e) => {
										console.log(
											"clicked: ",
											e.target.checked
										);
										if (e.target.checked) {
											// Select all users when "Select All" is checked
											setData({
												...data,
												user_ids: users.map(
													(user) => user.id
												),
											});
											setSelectAll(true);
										} else {
											// Deselect all users when "Select All" is unchecked
											setData({ ...data, user_ids: [] });
											setSelectAll(false);
										}
									}}
								/>
								<ListItemText
									primary={
										data.user_ids.length === users.length &&
										selectAll
											? "Deselect All"
											: "Select All"
									}
								/>
							</MenuItem>
							{users.map((user) => (
								<MenuItem key={user.id} value={user.id}>
									<Checkbox
										checked={
											data.user_ids.indexOf(user.id) > -1
										}
									/>
									<ListItemText primary={user.email} />
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<LoadingButton
						variant="contained"
						color="primary"
						type="submit"
						endIcon={<Send />}
						loading={loading}
					>
						Send
					</LoadingButton>
				</form>
			</Card>
		</Box>
	);
}
