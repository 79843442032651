import Api from "./base";

class ProfileApi extends Api {
	getAllProfiles = async () => {
		return await this.get("/users/");
	};
	getPriyoPayAppUsers = async () => {
		return await this.get("/users/priyo-pay-app/");
	};
	getProfileById = async (id) => {
		return await this.get(`/users/${id}/`);
	};
	getProfileSchema = async () => {
		return await this.get("/users/schema/");
	};
	updateProfile = async (id, data) => {
		return await this.put(`/users/${id}/`, data);
	};
	deleteProfile = async (id) => {
		return await this.delete(`/users/${id}/`);
	};
}

const profileApi = new ProfileApi();
export default profileApi;
