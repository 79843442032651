import { Box, Button } from "@mui/material";
import FormsTable from "./FormsTable";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import AddButton from "../../components/Buttons/AddButton";

export default function PriyoForm() {
	const navigate = useNavigate();
	return (
		<Box className="p-5 mx-auto flex items-center flex-col gap-10 w-full">
			<div className="md:hidden mb-4 w-full">
				<Button
					fullWidth
					variant="contained"
					color="primary"
					startIcon={<Add sx={{ fontSize: "2rem" }} />}
					size="large"
					className="text-lg"
					onClick={() => navigate("/priyo-form/add")}
				>
					Add News
				</Button>
			</div>
			<FormsTable />
			<div className="hidden md:block">
				<AddButton onClick={() => navigate("/priyo-form/add")} />
			</div>
		</Box>
	);
}
