import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
	Button,
	Card,
	CardActionArea,
	CardContent,
	ListItemText,
	Skeleton,
	TableHead,
	Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEye,
	faPenToSquare,
	faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { Add, Delete, Edit, Preview } from "@mui/icons-material";
import Confirmation from "../../components/Cards/Confirmation";
import CustomTablePaginationActions from "../../components/Tables/TablePaginationActions";
import trainerApi from "../../api/trainer";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { showError, showSuccess } from "../../App";
import profileApi from "../../api/profile";
import { convertFromSnake } from "../../services/commonUtils";
const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:last-child td, &:last-child th": {
		border: 0,
	},
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

function createData(id, name, email, updated_at) {
	return { id, name, email, updated_at: new Date(updated_at) };
}
export default function QuestionTable() {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [rows, setRows] = useState([]);
	const [cols, setCols] = useState([]);
	const [open, setOpen] = useState(false);
	const navigate = useNavigate();
	const [selected, setSelected] = useState({});
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	// State to store random widths
	const [randomWidths, setRandomWidths] = useState([]);

	useEffect(() => {
		// Generate 5 random widths when the component mounts
		const widths = Array.from(
			{ length: 5 },
			() => `${Math.floor(Math.random() * (90 - 10 + 1)) + 10}%`
		);
		setRandomWidths(widths);
	}, []);

	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	useEffect(() => {
		if (!data) return;
		setRows(
			data
				.map((item) => ({
					id: item.id,
					name: item.name,
					email: item.email,
					phone_number: item.phone_number,
					district: item.district,
				}))
				.sort((a, b) => a.id - b.id)
		);

		setCols({
			id: "5%",
			name: "30%",
			email: "30%",
			phone_number: "20%",
			district: "15%",
		});
		console.log(cols);
		setLoading(false);
	}, [data]);

	const fetchData = async () => {
		const response = await profileApi.getAllProfiles();
		if (response.success) {
			console.log(response.data);
			setData(response.data);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const handleDelete = async (row) => {
		const res = await profileApi.deleteProfile(row.id);
		if (res.success) {
			// console.log("Deleted:", row);
			showSuccess("Successfully deleted.");
		} else {
			// Revert the changes
			setData((prev) => [...prev, row]);
			showError("Failed to delete.");
		}
	};

	const columns = [
		{ id: "name" },
		{ id: "code", label: "ISO\u00a0Code", minWidth: 100 },
		{
			id: "population",
			label: "Population",
			minWidth: 170,
			align: "right",
			format: (value) => value.toLocaleString("en-US"),
		},
		{
			id: "size",
			label: "Size\u00a0(km\u00b2)",
			minWidth: 170,
			align: "right",
			format: (value) => value.toLocaleString("en-US"),
		},
		{
			id: "density",
			label: "Density",
			minWidth: 170,
			align: "right",
			format: (value) => value.toFixed(2),
		},
	];

	return (
		<div className="w-[90%]">
			<Confirmation
				open={open}
				setOpen={setOpen}
				onConfirm={() => {
					// console.log("Deleting ", selected);
					const newData = data.filter(
						(item) => item.id !== selected.id
					);
					setData(newData);
					handleDelete(selected);
					setSelected(null);
				}}
			/>
			{loading ? (
				<TableContainer component={Paper}>
					<Table aria-label="custom pagination table">
						<TableHead>
							<TableRow>
								<StyledTableCell className="!py-3">
									<div className="flex flex-row gap-4 justify-end">
										<Skeleton
											variant="text"
											height={30}
											className="w-[5%]"
										/>
										<Skeleton
											variant="text"
											height={30}
											className="w-[30%]"
										/>
										<Skeleton
											variant="text"
											height={30}
											className="w-[30%]"
										/>
										<Skeleton
											variant="text"
											// width={"10rem"}
											height={30}
											className="w-[20%]"
										/>
										<Skeleton
											variant="text"
											// width={"10rem"}
											height={30}
											className="w-[15%]"
										/>
									</div>
								</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{[...Array(5).keys()].map((index) => (
								<TableRow>
									<TableCell className="!py-3">
										<div className="flex flex-row gap-4 justify-end">
											<Skeleton
												variant="text"
												height={30}
												className="w-[5%]"
											/>
											<Skeleton
												variant="text"
												height={30}
												className="w-[30%]"
											/>
											<Skeleton
												variant="text"
												height={30}
												className="w-[30%]"
											/>
											<Skeleton
												variant="text"
												// width={"10rem"}
												height={30}
												className="w-[20%]"
											/>
											<Skeleton
												variant="text"
												// width={"10rem"}
												height={30}
												className="w-[15%]"
											/>
										</div>
									</TableCell>
								</TableRow>
							))}
						</TableBody>

						<TableFooter>
							<TableRow>
								<TableCell className="!py-3">
									<div className="flex flex-row gap-4 justify-end">
										<Skeleton
											variant="text"
											height={30}
											className="w-40"
										/>
										<Skeleton
											variant="text"
											height={30}
											className="w-24"
										/>
										<Skeleton
											variant="text"
											// width={"10rem"}
											height={30}
											className="w-12 md:w-32"
										/>
									</div>
								</TableCell>
							</TableRow>
						</TableFooter>
					</Table>
				</TableContainer>
			) : (
				<TableContainer component={Paper}>
					<Table aria-label="custom pagination table">
						<TableHead>
							<TableRow>
								<StyledTableCell>
									<div className="flex flex-row w-full">
										{Object.entries(cols).map(
											([key, width]) => (
												<div
													key={key}
													style={{
														width: width,
													}}
												>
													{convertFromSnake(key)}
												</div>
											)
										)}
									</div>
								</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{(rowsPerPage > 0
								? rows.slice(
										page * rowsPerPage,
										page * rowsPerPage + rowsPerPage
								  )
								: rows
							).map((row) => (
								<TableRow
									hover
									role="checkbox"
									tabIndex={-1}
									key={row.id}
									style={{
										cursor: "pointer",
									}}
								>
									<TableCell
										onClick={() =>
											navigate("/profiles/edit#" + row.id)
										}
									>
										<div className="flex flex-row w-full">
											{Object.keys(row).map((key) => (
												<div
													key={key}
													className={`truncate w-[${cols[key]}]`}
												>
													<Typography noWrap>
														{row[key]}
													</Typography>
												</div>
											))}
										</div>
									</TableCell>
								</TableRow>
							))}
							{emptyRows > 0 && (
								<TableRow style={{ height: 57 * emptyRows }}>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
						<TableFooter>
							<StyledTableRow>
								<TablePagination
									rowsPerPageOptions={[
										5,
										10,
										25,
										{ label: "All", value: -1 },
									]}
									colSpan={3}
									count={rows.length}
									rowsPerPage={rowsPerPage}
									page={page}
									slotProps={{
										select: {
											inputProps: {
												"aria-label": "rows per page",
											},
											native: true,
										},
									}}
									onPageChange={handleChangePage}
									onRowsPerPageChange={
										handleChangeRowsPerPage
									}
									ActionsComponent={
										CustomTablePaginationActions
									}
								/>
							</StyledTableRow>
						</TableFooter>
					</Table>
				</TableContainer>
			)}
		</div>
	);
}
