import "./App.css";
import Login from "./pages/Login";
import { AppBar, CssBaseline, Toolbar, createTheme } from "@mui/material";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
	Outlet,
} from "react-router-dom";
import ToggleMode from "./components/Buttons/ToggleButton";
import PrimaryLayout from "./components/Layouts/PrimaryLayout";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import config from "./config";
import Home from "./pages/Home";
import { getTokenFromLocalStorage, isTokenValid } from "./api/base";
import Storage from "./pages/Storage";
import Profiles from "./pages/Profiles";
import Advertisement from "./pages/Advertisement";
import Notification from "./pages/Notification";
import AddExcel from "./pages/Storage/AddExcel";
import EditProfilePage from "./pages/Profiles/EditProfilePage";
import News from "./pages/News";
import AddNews from "./pages/News/AddNews";
import EditNews from "./pages/News/EditNews";
import PreviewProfile from "./pages/Profiles/PreviewProfile";
import PriyoForm from "./pages/PriyoForm";
import AddForm from "./pages/PriyoForm/AddForm";
import FormDesigner from "./pages/PriyoPlay/FormDesigner";
import PriyoPlay from "./pages/PriyoPlay";
import Email from "./pages/Email";
import Campaign from "./pages/Campaign";
import AddCampaign from "./pages/Campaign/AddCampaign";
import EditCampaign from "./pages/Campaign/EditCampaign";
export const showToast = (message, type) => {
	console.log(message, type);
	if (type === "success") toast.success(message, {});
	else if (type === "error") toast.error(message, {});
	else {
		toast.dark(message, {});
	}
};
export const showSuccess = (message) => {
	showToast(message, "success");
};
export const showError = (message) => {
	showToast(message, "error");
};
export const showMessage = (message, res) => {
	showToast(message);
};

const Protected = () => {
	const isLoggedIn = true || isTokenValid();
	return isLoggedIn ? (
		<PrimaryLayout>
			<Outlet />
		</PrimaryLayout>
	) : (
		<Navigate to={"/login"} />
	);
};

const Public = () => {
	const isLoggedIn = isTokenValid();
	return isLoggedIn ? <Navigate to={"/"} /> : <Outlet />;
};

function App() {
	return (
		<>
			<Router>
				<div className="App h-full">
					<Toolbar className="fixed right-0 top-0 ml-auto flex flex-row justify-end p-3 items-center gap-4 z-100">
						<ToggleMode />
					</Toolbar>

					<div className="content">
						<Routes>
							<Route element={<Public />}>
								<Route path="/login" element={<Login />} />
							</Route>
							<Route element={<Protected />}>
								<Route path="/" element={<Home />} />
								<Route path="/storage" element={<Storage />} />
								<Route
									path="/storage/add"
									element={<AddExcel />}
								/>
								<Route
									path="/profiles"
									element={<Profiles />}
								/>
								<Route
									path="/profiles/edit"
									element={<EditProfilePage />}
								/>
								<Route
									path="/profiles/preview"
									element={<PreviewProfile />}
								/>

								<Route path="/news" element={<News />} />
								<Route path="/news/add" element={<AddNews />} />
								<Route
									path="/news/edit"
									element={<EditNews />}
								/>
								<Route
									path="/priyo-form"
									element={<PriyoForm />}
								/>
								<Route
									path="/priyo-form/add"
									element={<AddForm />}
								/>
								<Route
									path="/priyo-play"
									element={<PriyoPlay />}
								/>
								<Route
									path="/priyo-play/edit"
									element={<FormDesigner />}
								/>
								<Route
									path="/campaign"
									element={<Campaign />}
								/>
								<Route
									path="/campaign/add"
									element={<AddCampaign />}
								/>
								<Route
									path="/campaign/edit"
									element={<EditCampaign />}
								/>

								<Route
									path="/notification"
									element={<Notification />}
								/>
								<Route path="/email" element={<Email />} />
							</Route>
							<Route
								path="*"
								element={
									<Navigate
										replace
										to={config.loginRedirect}
									/>
								}
							/>
						</Routes>
					</div>
				</div>
			</Router>
			<ToastContainer
				style={{ width: "270px" }}
				position="top-right"
				theme="colored"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={true}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</>
	);
}

export default App;
