import Api from "./base";

class CampaignApi extends Api {
	createCampaign = async (data) => {
		return await this.post("/campaigns/", data);
	};
	getAllCampaigns = async () => {
		return await this.get("/campaigns/");
	};
	getCampaign = async (id) => {
		return await this.get(`/campaigns/${id}`);
	};
	updateCampaign = async (id, data) => {
		return await this.put(`/campaigns/${id}/`, data);
	};
	deleteCampaign = async (id) => {
		return await this.delete(`/campaigns/${id}/`);
	};
}

const campaignApi = new CampaignApi();
export default campaignApi;
