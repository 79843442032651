import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
	Button,
	Card,
	CardActionArea,
	CardContent,
	Chip,
	ListItemText,
	Skeleton,
	TableHead,
	Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEye,
	faPenToSquare,
	faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { Add, Delete, Edit, Preview } from "@mui/icons-material";
import Confirmation from "../../components/Cards/Confirmation";
import CustomTablePaginationActions from "../../components/Tables/TablePaginationActions";
import trainerApi from "../../api/trainer";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { showError, showSuccess } from "../../App";
import profileApi from "../../api/profile";
import newsApi from "../../api/news";
import quizApi from "../../api/quiz";
const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:last-child td, &:last-child th": {
		border: 0,
	},
}));

function createData(
	id,
	title,
	description,
	is_published,
	start_time,
	end_time,
	updated_at
) {
	return {
		id,
		title,
		description,
		is_published,
		start_time: start_time ? new Date(start_time) : null,
		end_time: end_time ? new Date(end_time) : null,
		updated_at: new Date(updated_at),
	};
}
export default function FormsTable() {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [rows, setRows] = useState([]);
	const [open, setOpen] = useState(false);
	const navigate = useNavigate();
	const [selected, setSelected] = useState({});
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	// State to store random widths
	const [randomWidths, setRandomWidths] = useState([]);

	useEffect(() => {
		// Generate 5 random widths when the component mounts
		const widths = Array.from(
			{ length: 5 },
			() => `${Math.floor(Math.random() * (90 - 10 + 1)) + 10}%`
		);
		setRandomWidths(widths);
	}, []);

	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	useEffect(() => {
		if (!data) return;
		setRows(
			data
				.map((item) =>
					createData(
						item.id,
						item.title,
						item.description,
						item.is_published,
						item.start_time,
						item.end_time,
						item.updated_at
					)
				)
				.sort((a, b) => b.updated_at - a.updated_at)
		);
		setLoading(false);
	}, [data]);

	const [currentTime, setCurrentTime] = useState(null);
	const fetchData = async () => {
		const response = await quizApi.getAllQuizes();
		if (response.success) {
			console.log(response.data);
			setData(response.data);
			setCurrentTime(new Date(response.time));
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const handleDelete = async (row) => {
		const res = await quizApi.deleteQuiz(row.id);
		if (res.success) {
			// console.log("Deleted:", row);
			showSuccess("Successfully deleted.");
		} else {
			// Revert the changes
			setData((prev) => [...prev, row]);
			showError("Failed to delete.");
		}
	};
	return (
		<div className="w-full max-w-3xl">
			<Confirmation
				open={open}
				setOpen={setOpen}
				onConfirm={() => {
					// console.log("Deleting ", selected);
					const newData = data.filter(
						(item) => item.id !== selected.id
					);
					setData(newData);
					handleDelete(selected);
					setSelected(null);
				}}
			/>
			{loading ? (
				<TableContainer component={Paper}>
					<Table aria-label="custom pagination table">
						<TableHead>
							<TableRow>
								<TableCell className="!py-3">
									<div className="flex flex-row gap-4 justify-end">
										<Skeleton
											variant="text"
											height={30}
											className="w-40"
										/>
										<Skeleton
											variant="text"
											height={30}
											className="w-24"
										/>
										<Skeleton
											variant="text"
											// width={"10rem"}
											height={30}
											className="w-12 md:w-32"
										/>
									</div>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{randomWidths.map((rWidth, index) => (
								<StyledTableRow
									key={index}
									className="font-sans text-base"
								>
									<TableCell className="min-w-[1rem] font-sans text-base">
										<div className="flex flex-col md:flex-row gap-2 ">
											<div
												className="w-full flex flex-col"
												style={{
													width: {
														md: `calc(100% - 8rem)`,
													},
												}}
											>
												<Skeleton
													variant="text"
													width="60%"
													height={25}
												/>
												<Skeleton
													variant="text"
													width="80%"
													height={25}
													// className="md:hidden"
												/>
											</div>

											<div className="flex flex-row w-[8rem] ml-auto z-10 gap-2">
												<Skeleton
													variant="circular"
													width={30}
													height={30}
												/>
												<Skeleton
													variant="circular"
													width={30}
													height={30}
													// style={{ marginLeft: 8 }}
												/>
												<Skeleton
													variant="circular"
													width={30}
													height={30}
													// style={{ marginLeft: 8 }}
												/>
											</div>
										</div>
									</TableCell>
								</StyledTableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			) : (
				<TableContainer component={Paper}>
					<Table aria-label="custom pagination table">
						<TableBody>
							{(rowsPerPage > 0
								? rows.slice(
										page * rowsPerPage,
										page * rowsPerPage + rowsPerPage
								  )
								: rows
							).map((row) => (
								<StyledTableRow
									key={row.id}
									className="font-sans text-base"
									role="checkbox"
									tabIndex={-1}
									hover
									onClick={() =>
										navigate("/priyo-play/edit#" + row.id)
									}
									sx={{
										cursor: "pointer",
									}}
								>
									<TableCell
										// sx={{ width: 20 }}
										// component="th"
										// scope="row"
										className="min-w-[1rem] font-sans text-base"
									>
										<div className="flex flex-col md:flex-row gap-2">
											<div
												className="w-full flex flex-col"
												style={{
													width: {
														md: `calc(100% - 9rem)`,
													},
												}}
											>
												<ListItemText
													primary={row.title}
													secondary={row.description}
												/>
											</div>
											<div className="flex flex-row w-[9rem] ml-auto z-10 items-center">
												<div className="w-full flex flex-col items-end gap-1">
													{row.start_time && (
														<Chip
															label={
																currentTime <
																row.start_time
																	? "Upcoming"
																	: row.end_time &&
																	  currentTime >
																			row.end_time
																	? "Ended"
																	: "Running"
															}
															size="small"
														/>
													)}

													{row.is_published && (
														<Chip
															label="Published"
															size="small"
															color="success"
														/>
													)}
												</div>
												{/* <div className="w-1/3">
													<IconButton
														onClick={() =>
															navigate(
																"/priyo-play/edit#" +
																	row.id
															)
														}
													>
														<Edit />
													</IconButton>
												</div> */}
												{/* <div className="w-1/3">
													<IconButton
														onClick={() => {
															setSelected(row);
															setOpen(true);
														}}
													>
														<Delete />
													</IconButton>
												</div> */}
											</div>
										</div>
									</TableCell>
								</StyledTableRow>
							))}
							{/* {emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={6} />
							</TableRow>
						)} */}
						</TableBody>
						<TableHead>
							<TableRow>
								<TablePagination
									rowsPerPageOptions={[
										5,
										10,
										25,
										{ label: "All", value: -1 },
									]}
									colSpan={3}
									count={rows.length}
									rowsPerPage={rowsPerPage}
									page={page}
									slotProps={{
										select: {
											inputProps: {
												"aria-label": "rows per page",
											},
											native: true,
										},
									}}
									onPageChange={handleChangePage}
									onRowsPerPageChange={
										handleChangeRowsPerPage
									}
									ActionsComponent={
										CustomTablePaginationActions
									}
								/>
							</TableRow>
						</TableHead>
					</Table>
				</TableContainer>
			)}
		</div>
	);
}
