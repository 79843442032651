import { useEffect, useState } from "react";

const useCountdown = (targetDate, currentTime, setCurrentTime) => {
	const countDownDate = new Date(targetDate).getTime();
	const [countDown, setCountDown] = useState(
		countDownDate - new Date(currentTime).getTime()
	);

	useEffect(() => {
		const interval = setInterval(() => {
			setCountDown(countDownDate - new Date(currentTime).getTime());
			setCurrentTime((prevTime) => new Date(prevTime.getTime() + 1000));
		}, 1000);
		return () => clearInterval(interval);
	}, [countDownDate, currentTime]);

	return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
	// calculate time left
	const days = Math.floor(countDown / (1000 * 60 * 60 * 24));

	const hours = Math.floor(
		(countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
	);
	const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
	const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

	return [days, hours, minutes, seconds];
};

export { useCountdown };
