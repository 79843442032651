import { Container, Divider, Typography } from "@mui/material";
import CardContainer from "../../components/Containers/CardContainer";
import CampaignCard from "./CampaignCard";
import { useEffect, useState } from "react";
import campaignApi from "../../api/campaign";
import { showError, showSuccess } from "../../App";
import Confirmation from "../../components/Cards/Confirmation";

export default function CampaignsGrid() {
	const [data, setData] = useState([]);
	const [open, setOpen] = useState(false);
	const [selected, setSelected] = useState({});
	const fetchData = async () => {
		const response = await campaignApi.getAllCampaigns();
		if (response.success) {
			setData(response.data);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const handleDelete = async (campaign) => {
		const response = await campaignApi.deleteCampaign(campaign.id);
		if (response.success) {
			showSuccess("Campaign deleted successfully");
		} else {
			setData((prev) => [...prev, campaign]);
			showError("Can't delete campaign");
		}
	};

	return (
		<CardContainer col={3}>
			{data.map((campaign) => (
				<CampaignCard
					campaign={campaign}
					handleDelete={() => {
						setSelected(campaign);
						setOpen(true);
					}}
				/>
			))}
		</CardContainer>
	);
}
