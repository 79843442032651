import { Box } from "@mui/material";
import NewsForm from "./NewsForm";
import config from "../../config.json";
import { useEffect, useState } from "react";
import GenericForm from "../../components/Forms/GenericForm";
import newsApi from "../../api/news";
import { showError } from "../../App";
import { useNavigate } from "react-router-dom";

export default function AddNews() {
	const [news, setNews] = useState();
	const [schema, setSchema] = useState();
	const navigate = useNavigate();
	const fetchSchema = async () => {
		const res = await newsApi.getNewsSchema();
		if (res.success) {
			const tmp_schema = res.data;
			const initialNews = {};
			for (const key in tmp_schema) {
				if (tmp_schema[key].editable) {
					initialNews[key] = null;
				}
			}
			setSchema(res.data);
			setNews(initialNews);
			console.log(initialNews);
		} else {
			showError("Can't fetch schema");
		}
	};
	useEffect(() => {
		fetchSchema();
	}, []);
	return (
		<Box
			className="p-5 mx-auto flex flex-col justify-center items-center gap-5 w-full"
			sx={{
				minHeight: `calc(100vh - ${config.topbarHeight}px)`,
			}}
		>
			{news && schema && (
				<GenericForm
					initialData={news}
					title={"Add News"}
					schema={schema}
					onSubmit={async (formData) => {
						const res = await newsApi.createNews(formData);
						if (res.success) {
							navigate("/news");
						} else {
							showError("Can't add news");
						}
					}}
				/>
			)}
		</Box>
	);
}
