import React, { useEffect, useState } from "react";
import GenericForm from "../../components/Forms/GenericForm";
import profileApi from "../../api/profile";
import { showError, showSuccess } from "../../App";
import { useLocation, useNavigate } from "react-router-dom";
import config from "../../config.json";
import {
	Box,
	Card,
	FormControl,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Save } from "@mui/icons-material";
import { convertFromSnake } from "../../services/commonUtils";

export default function AddForm() {
	const [priyoForm, setPriyoForm] = useState({
		title: "",
		subtitle: "",
	});
	const [loading, setLoading] = useState(false);
	const [schema, setSchema] = useState();
	const navigate = useNavigate();
	const fetchSchema = async () => {
		const res = await profileApi.getProfileSchema();
		if (res.success) {
			const tmp_schema = res.data;
			const initialNews = {};
			for (const key in tmp_schema) {
				if (tmp_schema[key].editable) {
					initialNews[key] = null;
				}
				tmp_schema[key]["selected"] = false;
			}
			setSchema(tmp_schema);
		} else {
			showError("Can't fetch schema");
		}
	};
	useEffect(() => {
		fetchSchema();
	}, []);

	return (
		<div
			className="p-5 w-full md:w-[60vw] flex-grow flex items-center mx-auto"
			style={{
				minHeight: `calc(100vh - ${config.topbarHeight}px)`,
			}}
		>
			<Card className="p-5 flex flex-col gap-3 rounded-lg w-full">
				<h1 className="text-3xl font-bold">Add Priyo Form</h1>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						setLoading(true);
						const formData = {
							title: priyoForm.title,
							schema: schema,
						};
						console.log(formData);
					}}
					className="flex flex-col gap-3"
				>
					<TextField
						variant="outlined"
						required
						type="text"
						label="Title"
						fullWidth
						multiline
						value={priyoForm.title}
						onChange={(e) =>
							setPriyoForm({
								...priyoForm,
								title: e.target.value,
							})
						}
					/>
					<TextField
						variant="outlined"
						required
						type="text"
						label="Description"
						fullWidth
						multiline
						value={priyoForm.description}
						onChange={(e) =>
							setPriyoForm({
								...priyoForm,
								description: e.target.value,
							})
						}
					/>
					<FormControl
						fullWidth
						className="input-field"
						variant="outlined"
						required
					>
						<InputLabel
							htmlFor="outlined-adornment"
							className="input-label"
						>
							Selected Fields
						</InputLabel>
						<Select
							multiple
							className="w-full"
							value={
								schema
									? Object.keys(schema)
											.filter(
												(key) => schema[key].selected
											)
											.map((key) => key)
									: []
							}
							onChange={(e) => {
								const selected = e.target.value;
								const tmp_schema = { ...schema };
								for (const key in tmp_schema) {
									tmp_schema[key]["selected"] =
										selected.includes(key);
								}
								setSchema(tmp_schema);
							}}
							input={<OutlinedInput label={"Selected Fields"} />}
						>
							{schema &&
								Object.keys(schema)
									.filter((key) => schema[key].editable)
									.map((key) => (
										<MenuItem key={key} value={key}>
											{convertFromSnake(key)}
										</MenuItem>
									))}
						</Select>
					</FormControl>
					<LoadingButton
						size="large"
						className="flex ml-auto flex-row gap-1 items-center min-w-full md:min-w-16"
						variant="contained"
						color="primary"
						loading={loading}
						type="submit"
					>
						<Save />
						<Typography className="font-semibold">Save</Typography>
					</LoadingButton>
				</form>
			</Card>
		</div>
	);
}
