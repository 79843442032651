import React, { useCallback, useEffect, useRef, useState } from "react";
import {
	Tabs,
	Tab,
	Box,
	Typography,
	Button,
	Card,
	InputAdornment,
	Radio,
	CardContent,
	Divider,
	Switch,
	FormControlLabel,
	OutlinedInput,
	CircularProgress,
	Checkbox,
	ListItemText,
	Chip,
	Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { TextField, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import {
	Clear,
	Delete,
	DeleteForever,
	LockClock,
	PlayArrow,
	Stop,
	TableChart,
	Timer,
} from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import debounce from "lodash/debounce";
import quizApi from "../../api/quiz";
import { useLocation, useNavigate } from "react-router-dom";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { convertFromSnake } from "../../services/commonUtils";
import { set } from "lodash";
import dayjs from "dayjs";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import fileApi from "../../api/file";
import { showError, showSuccess } from "../../App";
import CountdownTimer from "./Timer";
import { LoadingButton } from "@mui/lab";
import ResponsesTab from "./ResponsesTab";

function TabPanel(props) {
	const { children, value, index, ...other } = props;
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function SettingsTab({ form, setForm, mode, currentTime }) {
	const [isUploading, setIsUploading] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const navigate = useNavigate();

	const handleDelete = async () => {
		setIsDeleting(true);
		const res = await quizApi.deleteQuiz(form.id);
		if (res.success) {
			showSuccess("Successfully deleted.");
			navigate("/priyo-play");
		} else {
			showError("Failed to delete.");
		}
		setIsDeleting(false);
	};

	const handleThumbnailUpload = async (event) => {
		const file = event.target.files[0];
		if (file) {
			setIsUploading(true);
			const formData = new FormData();
			formData.append("file", file);
			const response = await fileApi.upload(formData);
			if (response.success) {
				setForm({
					...form,
					thumbnail: response.data.file_url,
				});
				showSuccess("Thumbnail uploaded successfully");
			} else {
				showError("Can't upload thumbnail");
			}
			setIsUploading(false);
		}
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<div className="mx-auto w-full flex flex-col gap-6">
				<Card>
					<CardContent className="flex flex-col gap-4">
						<Typography variant="h6" align="left">
							Settings
						</Typography>
						<Divider />

						{/* <Box
							className={`flex justify-between items-center p-3 rounded-lg`}
							sx={{
								backgroundColor: form.is_published
									? ""
									: "error.main",
								// color: "white",
							}}
						>
							<div className="flex items-center gap-2">
								<IconButton onClick={() => setOpen(true)}>
									<Timer />
								</IconButton>
								<Typography color="textPrimary">
									{"Start: " +
										(form.start_time
											? dayjs(form.start_time).format(
													"hh:mm a, DD MMM YYYY"
											  )
											: "Unspecified")}
								</Typography>
								<Typography color="textPrimary">
									{"End: " +
										(form.end_time
											? dayjs(form.end_time).format(
													"hh:mm a, DD MMM YYYY"
											  )
											: "Unspecified")}
								</Typography>
							</div>
							<div className="flex items-center">
								<Typography color="textPrimary">
									{form.is_published
										? "Published"
										: "Unpublished"}
								</Typography>
								<Switch
									checked={form.is_published}
									onChange={(e) => {
										setForm({
											...form,
											is_published: e.target.checked,
										});
									}}
									color="primary"
								/>
							</div>
						</Box> */}
						{/* <FormControlLabel
							control={
								<Switch
									checked={form.is_published}
									onChange={handlePublishToggle}
									color="primary"
								/>
							}
							label={
								form.is_published ? "Published" : "Unpublished"
							}
							className="block"
						/> */}
						{/* <div className="w-full flex flex-col md:flex-row gap-4 justify-center">
								<DateTimePicker
									label="Start Time"
									value={
										form.start_time &&
										!dayjs.isDayjs(form.start_time)
											? dayjs(form.start_time)
											: form.start_time
									}
									onChange={handleStartTimeChange}
									slotProps={{
										textField: {
											fullWidth: true,
										},
									}}
								/>
								<DateTimePicker
									label="End Time"
									value={
										form.end_time &&
										!dayjs.isDayjs(form.end_time)
											? dayjs(form.end_time)
											: form.end_time
									}
									onChange={handleEndTimeChange}
									slotProps={{
										textField: {
											fullWidth: true,
										},
									}}
								/>
							</div> */}

						<FormControl
							fullWidth
							className="input-field"
							variant="outlined"
							// disabled={mode !== "edit"}
						>
							<InputLabel
								htmlFor="outlined-adornment"
								className="input-label"
							>
								Required Fields
							</InputLabel>
							<Select
								multiple
								className="w-full"
								value={
									form.required_fields
										? Object.keys(form.required_fields)
												.filter(
													(key) =>
														form.required_fields[
															key
														].required
												)
												.map((key) => key)
										: []
								}
								onChange={(e) => {
									const selected = e.target.value;
									const tmp_schema = {
										...form.required_fields,
									};
									for (const key in tmp_schema) {
										tmp_schema[key]["required"] =
											selected.includes(key);
									}
									setForm({
										...form,
										schema: tmp_schema,
									});
								}}
								input={
									<OutlinedInput label={"Required Fields"} />
								}
								renderValue={(selected) => {
									return selected
										.map((value) => convertFromSnake(value))
										.join(", ");
								}}
							>
								{form.required_fields &&
									Object.keys(form.required_fields)
										.filter(
											(key) =>
												form.required_fields[key]
													.editable
										)
										.map((key) => (
											<MenuItem key={key} value={key}>
												<Checkbox
													checked={
														form.required_fields[
															key
														].required
													}
												/>
												<ListItemText
													primary={convertFromSnake(
														key
													)}
												/>
											</MenuItem>
										))}
							</Select>
						</FormControl>
					</CardContent>
				</Card>

				<Card>
					<CardContent className="flex flex-col gap-4">
						<Typography variant="h6" align="left">
							Thumbnail
						</Typography>
						<Divider />
						<div className="mt-2 mx-auto relative">
							<img
								src={
									form.thumbnail ||
									"https://media.istockphoto.com/id/1452662816/vector/no-picture-available-placeholder-thumbnail-icon-illustration-design.jpg?s=170667a&w=0&k=20&c=_KbmIjTy16NSO2KKB4yg2huUAL15hok-xYxKXB9qiP8="
								}
								alt="Form Thumbnail"
								className="w-52 h-52 object-cover rounded-lg border-2"
							/>
							{isUploading && (
								<div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-lg">
									<CircularProgress
										size={48}
										className="text-white"
									/>
								</div>
							)}
						</div>
						<input
							accept="image/*"
							style={{ display: "none" }}
							id="thumbnail-upload"
							type="file"
							onChange={handleThumbnailUpload}
						/>
						<label
							htmlFor="thumbnail-upload"
							className="w-52 mx-auto"
						>
							<Button
								variant="contained"
								component="span"
								startIcon={<CloudUploadIcon />}
								fullWidth
								// disabled={isUploading || mode !== "edit"}
								disabled={isUploading}
							>
								Upload Thumbnail
							</Button>
						</label>
					</CardContent>
				</Card>

				{/* Delete Button */}
				<div className="w-full flex justify-center">
					<LoadingButton
						variant="contained"
						color="error"
						startIcon={<DeleteForever />}
						disabled={
							form.start_time &&
							new Date(form.start_time) < currentTime &&
							(!form.end_time ||
								new Date(form.end_time) > currentTime)
						}
						onClick={handleDelete}
						loading={isDeleting}
						loadingPosition="start"
					>
						Delete Quiz
					</LoadingButton>
				</div>
			</div>
		</LocalizationProvider>
	);
}

function QuestionsTab({ form, setForm, mode }) {
	return (
		<div className="flex flex-col items-center gap-6 w-full">
			{form.questions.map((question, i) => (
				<Card
					className="w-full"
					sx={{
						borderColor: "primary.main",
					}}
					key={i}
				>
					<CardContent>
						<div className="flex flex-col">
							<div className="flex flex-col md:flex-row items-start justify-between mb-4 gap-4">
								<TextField
									variant="filled"
									placeholder="Question"
									value={question.title}
									onChange={(e) => {
										const newQuestions = [
											...form.questions,
										];
										newQuestions[i].title = e.target.value;
										setForm({
											...form,
											questions: newQuestions,
										});
									}}
									fullWidth
									multiline
									InputProps={{
										style: {
											padding: "8px", // Adjust padding as needed
										},
									}}
									disabled={mode !== "edit"}
								/>

								<FormControl
									variant="outlined"
									size="small"
									className="min-w-[200px] ml-auto"
									disabled={mode !== "edit"}
								>
									<InputLabel id="question-type-label">
										Question Type
									</InputLabel>
									<Select
										labelId="question-type-label"
										id="question-type-select"
										value={question.answer.type}
										onChange={(e) => {
											const newQuestions = [
												...form.questions,
											];

											if (e.target.value === "multiple") {
												newQuestions[i].answer = {
													type: "multiple",
													options: ["Option 1"],
													correct: null,
												};
											} else if (
												e.target.value === "checkbox"
											) {
												newQuestions[i].answer = {
													type: "checkbox",
													options: ["Option 1"],
													correct: [],
												};
											} else if (
												e.target.value === "short"
											) {
												newQuestions[i].answer = {
													type: "short",
													correct: "",
												};
											} else if (
												e.target.value === "paragraph"
											) {
												newQuestions[i].answer = {
													type: "paragraph",
													correct: "",
												};
											} else if (
												e.target.value === "dropdown"
											) {
												newQuestions[i].answer = {
													type: "dropdown",
													options: ["Option 1"],
													correct: 0,
												};
											}

											setForm({
												...form,
												questions: newQuestions,
											});
										}}
										label="Question Type"
									>
										{[
											{
												value: "multiple",
												label: "Multiple choice",
											},
											{
												value: "checkbox",
												label: "Checkbox",
											},
											{
												value: "short",
												label: "Short answer",
											},
											{
												value: "paragraph",
												label: "Paragraph",
											},
											{
												value: "dropdown",
												label: "Dropdown",
											},
										].map((option, index) => (
											<MenuItem
												value={option.value}
												key={index}
											>
												{option.label}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</div>
							<div className="space-y-2">
								{
									// Multiple choice question
									question.answer.type === "multiple" ? (
										<div className="flex flex-col items-start w-full gap-4">
											{question.answer.options.map(
												(option, j) => (
													<Box
														key={j}
														className="flex flex-row items-center w-full gap-2"
													>
														<Radio
															checked={
																question.answer
																	.correct ===
																j
															}
															onChange={() => {
																const newQuestions =
																	[
																		...form.questions,
																	];
																newQuestions[
																	i
																].answer.correct =
																	j;
																setForm({
																	...form,
																	questions:
																		newQuestions,
																});
															}}
															value={j}
															name="radio-buttons"
															// disabled={
															// 	option === ""
															// }
															// disabled={
															// 	mode !== "edit"
															// }
														/>
														<TextField
															fullWidth
															size="small"
															// variant="standard"
															autoComplete="off"
															value={option}
															onChange={(e) => {
																const newQuestions =
																	[
																		...form.questions,
																	];
																newQuestions[
																	i
																].answer.options[
																	j
																] =
																	e.target.value;
																setForm({
																	...form,
																	questions:
																		newQuestions,
																});
															}}
															required
															disabled={
																mode !== "edit"
															}
														/>
														<IconButton
															disabled={
																mode !== "edit"
															}
															onClick={() => {
																const newQuestions =
																	[
																		...form.questions,
																	];
																newQuestions[
																	i
																].options.splice(
																	j,
																	1
																);

																if (
																	question.answer ===
																	j
																) {
																	newQuestions[
																		i
																	].answer =
																		null;
																	setForm({
																		...form,
																		questions:
																			newQuestions,
																	});
																} else {
																	setForm({
																		...form,
																		questions:
																			newQuestions,
																	});
																}
															}}
														>
															<Clear />
														</IconButton>
													</Box>
												)
											)}
											<Box>
												<Button
													disabled={mode !== "edit"}
													startIcon={<AddIcon />}
													onClick={() => {
														const newQuestions = [
															...form.questions,
														];
														newQuestions[
															i
														].answer.options.push(
															"Option " +
																(newQuestions[i]
																	.answer
																	.options
																	.length +
																	1)
														);
														setForm({
															...form,
															questions:
																newQuestions,
														});
													}}
												>
													Add Option
												</Button>
											</Box>
										</div>
									) : question.answer.type === "checkbox" ? (
										<div className="flex flex-col items-start w-full gap-4">
											{question.answer.options.map(
												(option, j) => (
													<Box
														key={j}
														className="flex flex-row items-center w-full gap-2"
													>
														<Checkbox
															disabled={
																mode !== "edit"
															}
															checked={question.answer.correct.includes(
																j
															)}
															onChange={() => {
																const newQuestions =
																	[
																		...form.questions,
																	];
																if (
																	newQuestions[
																		i
																	].answer.correct.includes(
																		j
																	)
																) {
																	newQuestions[
																		i
																	].answer.correct.splice(
																		newQuestions[
																			i
																		].answer.correct.indexOf(
																			j
																		),
																		1
																	);
																} else {
																	console.log(
																		"Pushing ",
																		j
																	);
																	newQuestions[
																		i
																	].answer.correct.push(
																		j
																	);
																	newQuestions[
																		i
																	].answer.correct.sort(
																		(
																			a,
																			b
																		) =>
																			a -
																			b
																	);
																	console.log(
																		newQuestions[
																			i
																		].answer
																			.correct
																	);
																}
																setForm({
																	...form,
																	questions:
																		newQuestions,
																});
															}}
															value={j}
															name="checkbox-buttons"
															// disabled={
															// 	option === ""
															// }
														/>
														<TextField
															disabled={
																mode !== "edit"
															}
															fullWidth
															size="small"
															// variant="standard"
															autoComplete="off"
															value={option}
															onChange={(e) => {
																const newQuestions =
																	[
																		...form.questions,
																	];
																newQuestions[
																	i
																].answer.options[
																	j
																] =
																	e.target.value;
																setForm({
																	...form,
																	questions:
																		newQuestions,
																});
															}}
															required
														/>
														<IconButton
															disabled={
																mode !== "edit"
															}
															onClick={() => {
																const newQuestions =
																	[
																		...form.questions,
																	];
																newQuestions[
																	i
																].options.splice(
																	j,
																	1
																);

																if (
																	question.answer ===
																	j
																) {
																	newQuestions[
																		i
																	].answer =
																		null;
																	setForm({
																		...form,
																		questions:
																			newQuestions,
																	});
																} else {
																	setForm({
																		...form,
																		questions:
																			newQuestions,
																	});
																}
															}}
														>
															<Clear />
														</IconButton>
													</Box>
												)
											)}
											<Box>
												<Button
													disabled={mode !== "edit"}
													startIcon={<AddIcon />}
													onClick={() => {
														const newQuestions = [
															...form.questions,
														];
														newQuestions[
															i
														].answer.options.push(
															"Option " +
																(newQuestions[i]
																	.answer
																	.options
																	.length +
																	1)
														);
														setForm({
															...form,
															questions:
																newQuestions,
														});
													}}
												>
													Add Option
												</Button>
											</Box>
										</div>
									) : question.answer.type === "short" ? (
										<TextField
											disabled={mode !== "edit"}
											variant="standard"
											placeholder="Answer"
											value={question.answer.correct}
											onChange={(e) => {
												const newQuestions = [
													...form.questions,
												];
												newQuestions[i].answer.correct =
													e.target.value;
												setForm({
													...form,
													questions: newQuestions,
												});
											}}
											fullWidth
											multiline
											InputProps={{
												style: {
													padding: "8px", // Adjust padding as needed
												},
											}}
										/>
									) : question.answer.type === "paragraph" ? (
										<TextField
											disabled={mode !== "edit"}
											variant="standard"
											placeholder="Answer"
											value={question.answer.correct}
											onChange={(e) => {
												const newQuestions = [
													...form.questions,
												];
												newQuestions[i].answer.correct =
													e.target.value;
												setForm({
													...form,
													questions: newQuestions,
												});
											}}
											fullWidth
											multiline
											InputProps={{
												style: {
													padding: "8px", // Adjust padding as needed
												},
											}}
										/>
									) : question.answer.type === "dropdown" ? (
										<div className="flex flex-col items-start w-full gap-4">
											{question.answer.options.map(
												(option, j) => (
													<Box
														key={j}
														className="flex flex-row items-center w-full gap-2"
													>
														<TextField
															fullWidth
															size="small"
															disabled={
																mode !== "edit"
															}
															// variant="standard"
															autoComplete="off"
															value={option}
															onChange={(e) => {
																const newQuestions =
																	[
																		...form.questions,
																	];
																newQuestions[
																	i
																].answer.options[
																	j
																] =
																	e.target.value;
																setForm({
																	...form,
																	questions:
																		newQuestions,
																});
															}}
															required
														/>
														<IconButton
															disabled={
																mode !== "edit"
															}
															onClick={() => {
																const newQuestions =
																	[
																		...form.questions,
																	];
																newQuestions[
																	i
																].options.splice(
																	j,
																	1
																);

																if (
																	question.answer ===
																	j
																) {
																	newQuestions[
																		i
																	].answer =
																		null;
																	setForm({
																		...form,
																		questions:
																			newQuestions,
																	});
																} else {
																	setForm({
																		...form,
																		questions:
																			newQuestions,
																	});
																}
															}}
														>
															<Clear />
														</IconButton>
													</Box>
												)
											)}
											<Box>
												<Button
													disabled={mode !== "edit"}
													startIcon={<AddIcon />}
													onClick={() => {
														const newQuestions = [
															...form.questions,
														];
														newQuestions[
															i
														].answer.options.push(
															"Option " +
																(newQuestions[i]
																	.answer
																	.options
																	.length +
																	1)
														);
														setForm({
															...form,
															questions:
																newQuestions,
														});
													}}
												>
													Add Option
												</Button>
											</Box>
											<FormControl
												variant="outlined"
												size="small"
												className="min-w-[200px] mr-auto"
												// disabled={mode !== "edit"}
											>
												<InputLabel id="question-type-label">
													Correct Answer
												</InputLabel>
												<Select
													labelId="question-type-label"
													id="question-type-select"
													value={
														question.answer.correct
													}
													onChange={(e) => {
														const newQuestions = [
															...form.questions,
														];
														newQuestions[
															i
														].answer.correct =
															e.target.value;
														setForm({
															...form,
															questions:
																newQuestions,
														});
													}}
													label="Correct Answer"
												>
													{question.answer.options.map(
														(option, index) => (
															<MenuItem
																value={index}
																key={index}
															>
																{option}
															</MenuItem>
														)
													)}
												</Select>
											</FormControl>
										</div>
									) : (
										<></>
									)
								}
							</div>
							<TextField
								variant="filled"
								placeholder="Explanation"
								value={question.explanation}
								onChange={(e) => {
									const newQuestions = [...form.questions];
									newQuestions[i].explanation =
										e.target.value;
									setForm({
										...form,
										questions: newQuestions,
									});
								}}
								fullWidth
								multiline
								InputProps={{
									style: {
										padding: "8px", // Adjust padding as needed
									},
								}}
								// disabled={mode !== "edit"}
							/>

							<Divider sx={{ my: 2 }} />
							<div className="flex flex-row justify-between">
								<div className="flex flex-row gap-2 items-end">
									<TextField
										variant="standard"
										size="small"
										value={question.points}
										onChange={(e) => {
											const newQuestions = [
												...form.questions,
											];
											newQuestions[i].points = parseInt(
												e.target.value
											);
											setForm({
												...form,
												questions: newQuestions,
											});
										}}
										type="number"
										InputProps={{
											inputProps: {
												style: {
													padding: "8px", // Adjust padding as needed
												},
												min: 0,
											},
										}}
										className="w-16"
										disabled={mode !== "edit"}
									/>
									<Typography variant="h6">points</Typography>
								</div>
								<IconButton
									onClick={() => {
										const newQuestions = [
											...form.questions,
										];
										newQuestions.splice(i, 1);
										setForm({
											...form,
											questions: newQuestions,
										});
									}}
									disabled={mode !== "edit"}
								>
									<Delete />
								</IconButton>
							</div>
						</div>
					</CardContent>
				</Card>
			))}
			<Button
				variant="contained"
				color="primary"
				startIcon={<AddIcon />}
				onClick={() => {
					const newQuestions = [...form.questions];
					newQuestions.push({
						title: "Untitled Question",
						answer: {
							type: "multiple",
							options: ["Option 1"],
							correct: null,
						},
						points: 0,
					});
					setForm({ ...form, questions: newQuestions });
				}}
				disabled={mode !== "edit"}
			>
				Add question
			</Button>
		</div>
	);
}

function Title({ form, setForm }) {
	const [editingTitle, setEditingTitle] = useState(false);
	const handleTitleEdit = () => {
		if (editingTitle) {
			setForm({ ...form, title });
		}
		setEditingTitle(!editingTitle);
	};
	const [title, setTitle] = useState(form.title);

	useEffect(() => {
		setTitle(form.title);
	}, [form.title]);
	return (
		<>
			{editingTitle ? (
				<TextField
					value={title}
					onChange={(e) => setTitle(e.target.value)}
					variant="standard"
					fullWidth
					autoFocus
				/>
			) : (
				<Typography variant="h4">{form.title}</Typography>
			)}
			{editingTitle && (
				<IconButton
					onClick={() => setEditingTitle(!editingTitle)}
					size="small"
					className="ml-2"
				>
					<Clear />
				</IconButton>
			)}
			<IconButton onClick={handleTitleEdit} size="small" className="ml-2">
				{editingTitle ? <CheckIcon /> : <EditIcon />}
			</IconButton>
		</>
	);
}

function Description({ form, setForm }) {
	const [editingDescription, setEditingDescription] = useState(false);
	const [description, setDescription] = useState(form.description);
	const handleDescriptionEdit = () => {
		if (editingDescription) {
			setForm({ ...form, description });
		}
		setEditingDescription(!editingDescription);
	};
	useEffect(() => {
		setDescription(form.description);
	}, [form.description]);
	return (
		<>
			{editingDescription ? (
				<TextField
					value={description}
					onChange={(e) => setDescription(e.target.value)}
					variant="standard"
					fullWidth
					multiline
					autoFocus
				/>
			) : (
				<Typography
					variant="body2"
					sx={{
						whiteSpace: "pre-line",
					}}
					align="left"
				>
					{form.description}
				</Typography>
			)}
			{editingDescription && (
				<IconButton
					onClick={() => setEditingDescription(!editingDescription)}
					size="small"
					className="ml-2"
				>
					<Clear />
				</IconButton>
			)}
			<IconButton
				onClick={handleDescriptionEdit}
				size="small"
				className="ml-2"
			>
				{editingDescription ? <CheckIcon /> : <EditIcon />}
			</IconButton>
		</>
	);
}

function isDifferent(obj1, obj2) {
	function removeUpdatedAtField(obj) {
		const { updated_at, ...rest } = obj;
		return rest;
	}

	function cleanObj(form) {
		return removeUpdatedAtField(form);
	}

	return JSON.stringify(cleanObj(obj1)) !== JSON.stringify(cleanObj(obj2));
}
function SyncStatus({ form, savedForm, saving }) {
	function removeUpdatedAtField(obj) {
		const { updated_at, ...rest } = obj;
		return rest;
	}

	function cleanForm(form) {
		return removeUpdatedAtField(form);
	}

	const cleanedForm = cleanForm(form);
	const cleanedSavedForm = cleanForm(savedForm);

	const isDifferent =
		JSON.stringify(cleanedForm) !== JSON.stringify(cleanedSavedForm);

	// console.log("Compare: ", cleanedForm, cleanedSavedForm);
	return (
		<Typography variant="body2" className="px-4" color="textSecondary">
			{saving
				? "Saving..."
				: isDifferent
				? "Unsaved changes"
				: "All changes Saved"}
		</Typography>
	);
}

function deepCopy(obj) {
	return JSON.parse(JSON.stringify(obj));
}

const ExpiredNotice = (props) => {
	return (
		<div className="flex justify-center items-center">
			<div className="bg-gradient-to-r from-red-500 to-pink-500 rounded-lg shadow-lg overflow-hidden">
				<div className="px-4 py-3 bg-white bg-opacity-20 backdrop-blur-lg">
					<span className="text-2xl font-bold text-white drop-shadow-md">
						{props.msg}
					</span>
				</div>
			</div>
		</div>
	);
};

export default function FormDesigner() {
	const [value, setValue] = useState(0);
	const [form, setForm] = useState();
	const [savedForm, setSavedForm] = useState();
	const [saving, setSaving] = useState(false);
	const location = useLocation();
	const [id, setId] = useState(null);
	const [currentTime, setCurrentTime] = useState(null);

	useEffect(() => {
		setId(location.hash.substring(1));
	}, [location]);

	const fetchForm = async () => {
		const res = await quizApi.getQuizById(id);
		if (res.time) setCurrentTime(res.time);
		if (res.success) {
			setForm(deepCopy(res.data));
			console.log("Saving Form: ", deepCopy(res.data));
			setSavedForm(deepCopy(res.data));
		}
	};

	useEffect(() => {
		if (id) {
			fetchForm();
		}
	}, [id]);

	useEffect(() => {
		console.log("Updating Saved Form: ", savedForm);
	}, [savedForm]);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const updateQuiz = async (newForm, prevForm) => {
		// if (prevForm.start_time !== newForm.start_time) {
		// 	// start time changed
		// 	const currentTimePlus5Minutes = new Date(
		// 		new Date().getTime() + 5 * 60 * 1000
		// 	);
		// 	if (new Date(newForm.start_time) < currentTimePlus5Minutes) {
		// 		// Start time is less than current time + 5 minutes
		// 		// alert("Start time must be at least 5 minutes from now.");
		// 		return;
		// 	}
		// }
		// if (prevForm.end_time !== newForm.end_time) {
		// 	// end time changed
		// 	const startTimePlus5Minutes = new Date(
		// 		new Date(newForm.start_time).getTime() + 5 * 60 * 1000
		// 	);
		// 	if (new Date(newForm.end_time) < startTimePlus5Minutes) {
		// 		// End time is less than start time + 5 minutes
		// 		// alert("End time must be at least 5 minutes from start time.");
		// 		return;
		// 	}
		// }
		setSaving(true);
		const res = await quizApi.updateQuiz(newForm.id, newForm);
		if (res.time) setCurrentTime(res.time);
		if (res.success) {
			// console.log(res.data);
			setSavedForm(deepCopy(res.data));
			setForm(deepCopy(res.data));
		} else {
			// Update not available after start time
			setForm(deepCopy(prevForm));
		}
		setSaving(false);
	};

	const debouncedSave = useCallback(
		debounce((newForm, prevForm) => {
			updateQuiz(newForm, prevForm);
		}, 1000),
		[]
	);

	// useEffect(() => {
	// 	if (JSON.stringify(form) !== JSON.stringify(savedFormRef.current)) {
	// 		debouncedSave(form);
	// 	}
	// }, [form, debouncedSave]);

	useEffect(() => {
		if (form && savedForm && isDifferent(form, savedForm))
			debouncedSave(form, savedForm);
		console.log(
			form,
			savedForm,
			JSON.stringify(form) === JSON.stringify(savedForm)
		);
		// Cleanup on component unmount
		return () => {
			debouncedSave.cancel();
		};
	}, [form, debouncedSave]);

	return (
		form && (
			<div className="max-w-4xl mx-auto p-6 font-sans relative">
				<div className="flex items-center mb-2">
					<Title {...{ form, setForm }} />
				</div>

				<div className="flex items-center mb-4">
					<Description {...{ form, setForm }} />
				</div>

				{/* {form.start_time && (
					<Typography
						variant="h4"
						className="mb-4"
						sx={{
							color: "success.main",
						}}
					>
						{form.start_time &&
						currentTime > new Date(form.start_time)
							? "Quiz is running"
							: ""}
					</Typography>
				)} */}
				{
					<div>
						{form.start_time &&
						new Date() < new Date(form.start_time) ? (
							<>
								<ExpiredNotice msg="Quiz starts in" />
								<CountdownTimer
									targetDate={form.start_time}
									flag={"start"}
									currentTime={currentTime}
									setCurrentTime={setCurrentTime}
								/>
							</>
						) : (
							form.end_time && (
								<CountdownTimer
									targetDate={form.end_time}
									flag={"end"}
									currentTime={currentTime}
									setCurrentTime={setCurrentTime}
								/>
							)
						)}
					</div>
				}

				<Box
					sx={{ borderBottom: 1, borderColor: "divider" }}
					className="flex flex-row justify-between items-center"
				>
					<Tabs
						value={value}
						onChange={handleChange}
						aria-label="form tabs"
					>
						<Tab label="Questions" />
						<Tab label="Responses" />
						<Tab label="Settings" />
					</Tabs>
					<SyncStatus {...{ form, savedForm, saving }} />
				</Box>

				<TabPanel value={value} index={0}>
					{form.questions && (
						<QuestionsTab
							{...{ form, setForm }}
							mode={
								form.start_time &&
								currentTime > new Date(form.start_time)
									? "view"
									: "edit"
							}
						/>
					)}
				</TabPanel>

				<TabPanel value={value} index={1}>
					{form.submissions && (
						<ResponsesTab
							{...{ form, setForm, currentTime }}
							mode={
								form.start_time &&
								currentTime > new Date(form.start_time)
									? "view"
									: "edit"
							}
						/>
					)}
				</TabPanel>

				<TabPanel value={value} index={2}>
					<SettingsTab
						{...{ form, setForm, currentTime }}
						mode={
							form.start_time &&
							currentTime > new Date(form.start_time)
								? "view"
								: "edit"
						}
					/>
					{/* <Typography>Settings content placeholder</Typography> */}
				</TabPanel>
			</div>
		)
	);
}
