import { Box, Button } from "@mui/material";
import config from "../../config";
import NewsTable from "./NewsTable";
import { useNavigate } from "react-router-dom";
import { Add } from "@mui/icons-material";
import AddButton from "../../components/Buttons/AddButton";

export default function News() {
	const navigate = useNavigate();
	return (
		<Box className="p-5 mx-auto flex items-center flex-col gap-10 w-full">
			<div className="md:hidden mb-4 w-full">
				<Button
					fullWidth
					variant="contained"
					color="primary"
					startIcon={<Add sx={{ fontSize: "2rem" }} />}
					size="large"
					className="text-lg"
					onClick={() => navigate("/news/add")}
				>
					Add News
				</Button>
			</div>
			<NewsTable />
			<div className="hidden md:block">
				<AddButton onClick={() => navigate("/news/add")} />
			</div>
		</Box>
	);
}
