import {
	Box,
	Card,
	CircularProgress,
	Divider,
	InputAdornment,
	TextField,
	Typography,
} from "@mui/material";
import config from "../../config";
import { LoadingButton } from "@mui/lab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Clear, Upload } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { showError, showSuccess } from "../../App";
import Papa from "papaparse";
import storageApi from "../../api/storage";

function ExcelUploadForm() {
	const [file, setFile] = useState("");
	const [source, setSource] = useState("");
	const [filePath, setFilePath] = useState("");
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const uploadFile = (e) => {
		setFile(e.target.files[0]);
		setFilePath(e.target.value);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		const text = await file.text();
		const table = [];
		const data = [];

		Papa.parse(text, {
			header: true,
			skipEmptyLines: true,
			transform: (value) => (value === "" ? null : value), // Transform empty strings to null
			complete: (results) => {
				data.push(...results.data);
				table.push(...results.data.map(Object.values));
			},
			error: (error) => {
				showError("Error parsing CSV: " + error.message);
				setLoading(false);
			},
		});

		console.log(table);
		console.log(data);

		const result = await storageApi.uploadFile(
			{ source: source, name: file.name },
			data
		);
		if (!result.success) {
			showError("Can't upload the file");
		} else {
			showSuccess("File uploaded successfully");
		}

		setLoading(false);
	};
	const handleReset = () => {
		setFilePath("");
		setFile("");
	};
	return (
		<Card className="w-full p-3 flex flex-col gap-3 md:w-[30rem] mx-auto">
			<Typography variant="h5">Add Excel Data</Typography>
			<Divider />
			<form onSubmit={handleSubmit} className="flex flex-col gap-5">
				<TextField
					type="name"
					value={source}
					onChange={(e) => setSource(e.target.value)}
					label="Data Source"
					required
					autoComplete="off"
				/>
				<TextField
					type="file"
					onChange={uploadFile}
					value={filePath}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								{filePath && !loading && (
									<Clear
										className="cursor-pointer"
										onClick={handleReset}
									/>
								)}
							</InputAdornment>
						),
					}}
					disabled={loading}
					required
				/>
				<LoadingButton
					variant="contained"
					color="primary"
					type="submit"
					startIcon={<Upload />}
					loading={loading}
				>
					Upload
				</LoadingButton>
			</form>
		</Card>
	);
}
export default function AddExcel() {
	return (
		<Box
			className="p-5 mx-auto flex flex-col justify-center items-center gap-5 w-full"
			sx={{
				minHeight: `calc(100vh - ${config.topbarHeight}px)`,
			}}
		>
			<ExcelUploadForm />
		</Box>
	);
}
