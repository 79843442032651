import { Box } from "@mui/material";
import config from "../../config";

export default function Email() {
	return (
		<Box
			className="p-5 mx-auto flex items-center flex-col gap-10 justify-center w-full"
			sx={{
				minHeight: `calc(100vh - ${config.topbarHeight}px)`,
			}}
		></Box>
	);
}
