import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
export default function AddButton({ onClick }) {
	return (
		<div className="fixed bottom-5 z-10 right-5 items-center justify-center ">
			<Button
				onClick={onClick}
				variant="contained"
				color="primary"
				className="w-16 h-16 rounded-full justify-center inline-flex items-center p-4"
			>
				<Add sx={{ fontSize: "4rem" }} />
			</Button>
		</div>
	);
}
