import Api from "./base";

class StorageApi extends Api {
	getUploadedData = async () => {
		return await this.get("/external_data/");
	};
	uploadFile = async (file, users) => {
		return await this.post("/external_data/", {
			data_source: file.source,
			file_name: file.name,
			users: users,
		});
	};
}

const storageApi = new StorageApi();
export default storageApi;
