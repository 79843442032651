import React, { useEffect, useState } from "react";
import GenericForm from "../../components/Forms/GenericForm";
import profileApi from "../../api/profile";
import { showError, showSuccess } from "../../App";
import { useLocation } from "react-router-dom";
import newsApi from "../../api/news";

export default function EditNews() {
	// This could be fetched from your API
	const [news, setNews] = useState();
	const [schema, setSchema] = useState();
	const location = useLocation();
	const [id, setId] = useState(null);
	useEffect(() => {
		setId(location.hash.substring(1));
	}, [location]);

	const handleSubmit = async (formData) => {
		const res = await newsApi.updateNews(id, formData);
		if (res.success) {
			showSuccess("Changes saved successfully");
		} else {
			showError("Can't save changes");
		}
	};

	const fetchNews = async () => {
		const res = await newsApi.getNewsById(id);
		console.log(res);
		if (res.success) {
			setNews(res.data);
		} else {
			showError("Can't fetch profile");
		}
	};

	const fetchSchema = async () => {
		const res = await newsApi.getNewsSchema();
		if (res.success) {
			setSchema(res.data);
		} else {
			showError("Can't fetch schema");
		}
	};

	useEffect(() => {
		if (id) {
			fetchNews();
		}
	}, [id]);

	useEffect(() => {
		fetchSchema();
	}, []);

	return (
		news &&
		schema && (
			<GenericForm
				initialData={news}
				onSubmit={handleSubmit}
				schema={schema}
				title="Edit News"
			/>
		)
	);
}
