import { Add, Clear } from "@mui/icons-material";
import {
	Autocomplete,
	Box,
	Button,
	Card,
	CardContent,
	IconButton,
	TextField,
} from "@mui/material";

function getOrdinalSuffix(num) {
	const remainder = num % 10;
	const remainder100 = num % 100;

	if (remainder === 1 && remainder100 !== 11) {
		return `${num}st`;
	} else if (remainder === 2 && remainder100 !== 12) {
		return `${num}nd`;
	} else if (remainder === 3 && remainder100 !== 13) {
		return `${num}rd`;
	} else {
		return `${num}th`;
	}
}
export default function WinnerTable({ form, setForm }) {
	return (
		<Card
			className="w-full"
			sx={{
				borderColor: "primary.main",
			}}
		>
			<CardContent className="flex flex-col gap-4">
				{form.winners.map((winner, i) => (
					<Box
						key={i}
						className="flex flex-row items-center w-full gap-2"
					>
						<Autocomplete
							disablePortal
							fullWidth
							id="combo-box-demo"
							options={form.submissions.map((submission) => ({
								label: submission.user.email,
								value: submission.id,
							}))}
							value={
								form.winners[i]
									? {
											label: form.submissions.find(
												(submission) =>
													submission.id ===
													form.winners[i]
											)?.user.email,
											value: form.winners[i],
									  }
									: null
							}
							sx={{ width: "100%" }}
							onChange={(e, newValue) => {
								const newWinners = [...form.winners];
								newWinners[i] = newValue.value;
								setForm({ ...form, winners: newWinners });
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									fullWidth
									label={getOrdinalSuffix(i + 1) + " Winner"}
								/>
							)}
						/>
						<IconButton
							onClick={() => {
								const newWinners = [...form.winners];
								newWinners.splice(i, 1);
								setForm({ ...form, winners: newWinners });
							}}
						>
							<Clear />
						</IconButton>
					</Box>
				))}
				<Box className="flex justify-start">
					<Button
						startIcon={<Add />}
						onClick={() => {
							const newWinners = [...form.winners];
							// newWinners.push(
							// 	"winner." +
							// 		(newWinners.length + 1) +
							// 		"@gmail.com"
							// );
							newWinners.push(null);
							setForm({ ...form, winners: newWinners });
						}}
					>
						Add Winner
					</Button>
				</Box>
			</CardContent>
		</Card>
	);
}
