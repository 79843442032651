import { Delete, Edit } from "@mui/icons-material";
import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Divider,
	Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import campaignApi from "../../api/campaign";
import { showSuccess } from "../../App";

export default function CampaignCard({ campaign, handleDelete }) {
	const navigate = useNavigate();

	return (
		<Card
			sx={{
				maxWidth: 345,
				height: "100%",
				display: "flex",
				flexDirection: "column",
			}}
		>
			<CardMedia
				component="img"
				image={
					campaign.image ||
					"https://via.placeholder.com/300x140?text=No+Thumbnail"
				}
				alt={campaign.title}
				sx={{
					objectFit: "cover",
					width: "100%",
					// height: "140px",
					aspectRatio: "16/9",
				}}
			/>
			<Divider />
			<CardContent sx={{ flexGrow: 1 }}>
				<Typography
					// gutterBottom
					variant="body2"
					component="div"
					noWrap
					align="left"
				>
					{campaign.title}
				</Typography>
				<Typography
					// gutterBottom
					variant="caption"
					component="div"
					color="textSecondary"
					align="left"
				>
					{campaign.description}
				</Typography>
				<Typography
					// gutterBottom
					variant="caption"
					component="div"
					color="textSecondary"
					align="left"
				>
					{campaign.url}
				</Typography>
			</CardContent>
			<Divider />
			<CardActions sx={{ justifyContent: "space-between" }}>
				<Button
					size="small"
					color="error"
					startIcon={<Delete />}
					onClick={handleDelete}
				>
					Delete
				</Button>
				<Button
					size="small"
					startIcon={<Edit />}
					onClick={() => navigate("/campaign/edit#" + campaign.id)}
				>
					Edit
				</Button>
			</CardActions>
		</Card>
	);
}
