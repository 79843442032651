import Api from "./base";

class NotificationApi extends Api {
	sendNotification = async (data) => {
		return await this.post("/users/send-push-notifications/", data);
	};
}

const notificationApi = new NotificationApi();
export default notificationApi;
