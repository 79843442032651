import axios from "axios";
import { API_BASE_URL } from "./base";
import Api from "./base";

class AuthApi extends Api {
	login = async (data) => {
		try {
			let res = await axios.post(API_BASE_URL + "/login/", data);
			return {
				success: true,                         
				data: res.data,
			};
		} catch (err) {
			if (err.hasOwnProperty("response")) {
				return err.response.data;
			} else {
				return {
					success: false,
					error: "Can't connect to server",
				};
			}
		}
	};

	logout = async () => {
		return await this.post("/logout");
	};
}
const authApi = new AuthApi();
export default authApi;
