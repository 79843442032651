import {
	Box,
	Button,
	Card,
	CircularProgress,
	Divider,
	TextField,
	Typography,
} from "@mui/material";
import { useState } from "react";
import fileApi from "../../api/file";
import { showError, showSuccess } from "../../App";
import { LoadingButton } from "@mui/lab";
import { Add, CloudUpload, Send } from "@mui/icons-material";

export default function CampaignFields({ data, setData, mode, handleSubmit }) {
	const [loading, setLoading] = useState(false);
	const [isUploading, setIsUploading] = useState(false);
	const handleThumbnailUpload = async (event) => {
		const file = event.target.files[0];
		if (file) {
			setIsUploading(true);
			const formData = new FormData();
			formData.append("file", file);
			const response = await fileApi.upload(formData);
			if (response.success) {
				setData((prev) => ({
					...prev,
					image: response.data.file_url,
				}));
				showSuccess("Thumbnail uploaded successfully");
			} else {
				showError("Can't upload thumbnail");
			}
			setIsUploading(false);
		}
	};
	return (
		<Card className="w-full p-5 flex flex-col gap-3 md:w-[30rem] mx-auto">
			<Typography variant="h5">
				{mode === "edit" ? "Edit" : "Add"} Campaign
			</Typography>
			<Divider />
			<form onSubmit={handleSubmit} className="flex flex-col gap-5">
				<TextField
					type="name"
					value={data.title}
					onChange={(e) =>
						setData({ ...data, title: e.target.value })
					}
					label="Title"
					required
					autoComplete="off"
					disabled={loading}
				/>
				<TextField
					type="name"
					onChange={(e) =>
						setData({ ...data, description: e.target.value })
					}
					value={data.description}
					disabled={loading}
					label="Description"
					required
				/>
				<TextField
					type="url"
					onChange={(e) => setData({ ...data, url: e.target.value })}
					value={data.url}
					disabled={loading}
					label="Base Url"
					required
				/>
				<Box
					className="border-[2px] flex flex-col p-3 gap-3 rounded-md"
					sx={{
						borderColor: "divider",
					}}
				>
					<div className="mx-auto flex flex-col">
						<img
							src={
								data.image ||
								"https://media.istockphoto.com/id/1452662816/vector/no-picture-available-placeholder-thumbnail-icon-illustration-design.jpg?s=170667a&w=0&k=20&c=_KbmIjTy16NSO2KKB4yg2huUAL15hok-xYxKXB9qiP8="
							}
							alt="Form Thumbnail"
							className="object-cover rounded-lg border-2 aspect-video"
						/>
						{isUploading && (
							<div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-lg">
								<CircularProgress
									size={48}
									className="text-white"
								/>
							</div>
						)}
					</div>
					<input
						accept="image/*"
						style={{ display: "none" }}
						id="thumbnail-upload"
						type="file"
						onChange={handleThumbnailUpload}
					/>
					<label htmlFor="thumbnail-upload" className="mx-auto">
						<Button
							variant="contained"
							component="span"
							startIcon={<CloudUpload />}
							fullWidth
							// disabled={isUploading || mode !== "edit"}
							disabled={isUploading}
						>
							Upload OG Image
						</Button>
					</label>
				</Box>
				<LoadingButton
					variant="contained"
					color="primary"
					type="submit"
					endIcon={<Send />}
					loading={loading}
				>
					Submit
				</LoadingButton>
			</form>
		</Card>
	);
}
