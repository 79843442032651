import Api from "./base";

class FileApi extends Api {
	upload = async (data) => {
		return await this.post("/upload/", data);
	};
}

const fileApi = new FileApi();
export default fileApi;
