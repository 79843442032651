import Api from "./base";

class QuizApi extends Api {
	getAllQuizes = async () => {
		return await this.get("/quiz/");
	};
	getQuizById = async (id) => {
		return await this.get(`/quiz/${id}/`);
	};
	getQuizSchema = async () => {
		return await this.get("/quiz/schema/");
	};
	updateQuiz = async (id, data) => {
		return await this.put(`/quiz/${id}/`, data);
	};
	deleteQuiz = async (id) => {
		return await this.delete(`/quiz/${id}/`);
	};
	createQuiz = async (data) => {
		return await this.post("/quiz/", data);
	};
}

const quizApi = new QuizApi();
export default quizApi;
