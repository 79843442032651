import React, { useCallback, useEffect, useRef, useState } from "react";
import {
	Tabs,
	Tab,
	Box,
	Typography,
	Button,
	Card,
	InputAdornment,
	Radio,
	CardContent,
	Divider,
	Switch,
	FormControlLabel,
	OutlinedInput,
	CircularProgress,
	Checkbox,
	ListItemText,
	Chip,
	Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { TextField, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import {
	Clear,
	Delete,
	DeleteForever,
	LockClock,
	PlayArrow,
	Stop,
	TableChart,
	Timer,
} from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import debounce from "lodash/debounce";
import quizApi from "../../api/quiz";
import { useLocation, useNavigate } from "react-router-dom";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { convertFromSnake } from "../../services/commonUtils";
import { set } from "lodash";
import dayjs from "dayjs";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import fileApi from "../../api/file";
import { showError, showSuccess } from "../../App";
import CountdownTimer from "./Timer";
import { LoadingButton } from "@mui/lab";
import SubmissionTable from "./SubmissionTable";
import WinnerTable from "./WinnerTable";

function QuizTime({ open, setOpen, onConfirm, form, mode }) {
	const handleClose = () => {
		setOpen(false);
	};
	const [startTime, setStartTime] = useState(
		form.start_time ? dayjs(form.start_time) : null
	);
	const [endTime, setEndTime] = useState(
		form.end_time ? dayjs(form.end_time) : null
	);

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<div className="w-full flex flex-col gap-4 justify-center p-6">
				<DateTimePicker
					label="Start Time"
					value={startTime}
					onChange={(newValue) => {
						setStartTime(newValue);
					}}
					slotProps={{
						textField: {
							fullWidth: true,
						},
					}}
					minDateTime={mode === "edit" ? dayjs() : undefined}
					maxDateTime={endTime ? endTime : undefined}
					disabled={mode !== "edit"}
				/>
				<DateTimePicker
					label="End Time"
					value={
						endTime && !dayjs.isDayjs(endTime)
							? dayjs(endTime)
							: endTime
					}
					onChange={(newValue) => {
						setEndTime(newValue);
					}}
					slotProps={{
						textField: {
							fullWidth: true,
						},
					}}
					minDateTime={startTime ? startTime : undefined}
					disabled={!startTime}
				/>
				<Button
					variant="contained"
					onClick={() => {
						// if (!startTime || !endTime) {
						// 	showError("Please select both start and end time");
						// 	return;
						// }

						onConfirm(startTime, endTime);
						handleClose();
					}}
					disabled={!startTime || !endTime}
				>
					Done
				</Button>
			</div>
		</Dialog>
	);
}

function QuizSchedule({ form, setForm, mode, currentTime }) {
	const [editingStart, setEditingStart] = useState(false);
	const [editingEnd, setEditingEnd] = useState(false);
	const [startTime, setStartTime] = useState(
		form.start_time ? dayjs(form.start_time) : null
	);
	const [endTime, setEndTime] = useState(
		form.end_time ? dayjs(form.end_time) : null
	);

	useEffect(() => {
		setStartTime(form.start_time ? dayjs(form.start_time) : null);
		setEndTime(form.end_time ? dayjs(form.end_time) : null);
	}, [form.start_time, form.end_time]);

	const handleStartEdit = () => {
		if (editingStart) {
			setForm({
				...form,
				start_time: startTime ? startTime.toDate() : null,
			});
		}
		setEditingStart(!editingStart);
	};

	const handleEndEdit = () => {
		if (editingEnd) {
			setForm({
				...form,
				end_time: endTime ? endTime.toDate() : null,
			});
		}
		setEditingEnd(!editingEnd);
	};

	return (
		<>
			<div className="flex items-center">
				<DateTimePicker
					label="Start Time"
					value={startTime ? startTime : null}
					onChange={(newValue) => {
						setStartTime(newValue);
					}}
					slotProps={{
						textField: {
							fullWidth: true,
						},
					}}
					disabled={!editingStart}
					minDateTime={
						form.start_time &&
						currentTime < new Date(form.start_time)
							? dayjs()
							: undefined
					}
					maxDateTime={
						form.end_time ? dayjs(form.end_time) : undefined
					}
				/>
				{editingStart && (
					<IconButton
						onClick={() => {
							setStartTime(
								form.start_time ? dayjs(form.start_time) : null
							);
							setEditingStart(false);
						}}
						size="small"
						className="ml-2"
						sx={{
							height: 45,
							width: 45,
						}}
					>
						<Clear />
					</IconButton>
				)}
				<IconButton
					onClick={handleStartEdit}
					size="small"
					className="ml-2"
					sx={{
						height: 45,
						width: 45,
					}}
					disabled={
						(form.start_time &&
							currentTime > new Date(form.start_time)) ||
						editingEnd
					}
				>
					{editingStart ? <CheckIcon /> : <EditIcon />}
				</IconButton>
			</div>

			<div className="flex items-center">
				<DateTimePicker
					label="End Time"
					value={endTime ? endTime : null}
					onChange={(newValue) => {
						setEndTime(newValue);
					}}
					slotProps={{
						textField: {
							fullWidth: true,
						},
					}}
					disabled={!editingEnd}
					minDateTime={
						form.start_time
							? editingEnd ||
							  (form.end_time &&
									currentTime < new Date(form.end_time))
								? dayjs(
										Math.max(
											dayjs(form.start_time).valueOf(),
											currentTime.getTime()
										)
								  )
								: dayjs(form.start_time)
							: undefined
					}
				/>
				{editingEnd && (
					<IconButton
						onClick={() => {
							setEndTime(
								form.end_time ? dayjs(form.end_time) : null
							);
							setEditingEnd(false);
						}}
						size="small"
						className="ml-2"
						sx={{
							height: 45,
							width: 45,
						}}
					>
						<Clear />
					</IconButton>
				)}
				<IconButton
					onClick={handleEndEdit}
					size="small"
					className="ml-2"
					sx={{
						height: 45,
						width: 45,
					}}
					disabled={editingStart || !form.start_time}
				>
					{editingEnd ? <CheckIcon /> : <EditIcon />}
				</IconButton>
			</div>
		</>
	);
}
export default function ResponsesTab({ form, setForm, mode, currentTime }) {
	const [open, setOpen] = useState(false);
	const formatDate = (date) => {
		return date
			? dayjs(date).format("hh:mm a, DD MMM YYYY")
			: "Unspecified";
	};

	const bgColor = form.is_published ? "" : "error.main";
	const textColor = form.is_published ? "success.dark" : "error.dark";

	// Dummy data for submissions
	const dummySubmissions = [
		{
			name: "John Doe",
			completed_at: "10:30 AM, 14 Aug 2024",
			points: 85,
		},
		{
			name: "Jane Smith",
			completed_at: "11:45 AM, 14 Aug 2024",
			points: 92,
		},
		{
			name: "Bob Johnson",
			completed_at: "02:15 PM, 14 Aug 2024",
			points: 78,
		},
		{
			name: "Alice Brown",
			completed_at: "04:00 PM, 10 Aug 2023",
			points: 95,
		},
		{
			name: "Charlie Wilson",
			completed_at: "05:30 PM, 14 Aug 2024",
			points: 88,
		},
		{
			name: "Alice Brown",
			completed_at: "04:00 PM, 14 Aug 2024",
			points: 95,
		},
		{
			name: "Charlie Wilson",
			completed_at: "05:30 PM, 14 Aug 2024",
			points: 88,
		},
	];

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<div className="flex flex-col items-center gap-6 w-full">
				<Card className="w-full">
					<CardContent>
						<QuizTime
							open={open}
							setOpen={setOpen}
							onConfirm={(startTime, endTime) => {
								setForm({
									...form,
									start_time: startTime
										? startTime.toDate()
										: null,
									end_time: endTime ? endTime.toDate() : null,
								});
							}}
							form={form}
							mode={mode}
						/>
						<div className="flex justify-between items-center mb-4">
							<Typography variant="h5" component="h2">
								{form.submissions.length} responses
							</Typography>
						</div>
						<Box
							className="p-4 rounded-lg border-[1px] border-solid "
							sx={{
								backgroundColor: bgColor,
								transition: "background-color 0.3s ease",
								borderColor: "input.border",
							}}
						>
							<div className="flex justify-between items-center mb-3">
								<Typography variant="h6">
									Form Status
								</Typography>
								<div className="flex items-center gap-2">
									<Typography>
										{form.is_published
											? "Published"
											: "Unpublished"}
									</Typography>
									<Switch
										checked={form.is_published}
										onChange={(e) => {
											if (e.target.checked) {
												setForm({
													...form,
													is_published:
														e.target.checked,
												});
											} else {
												setForm({
													...form,
													start_time: null,
													end_time: null,
													is_published:
														e.target.checked,
												});
											}
										}}
										color="primary"
										disabled={mode !== "edit"}
									/>
								</div>
							</div>

							{/* {form.is_published && (
								<div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
									<div className="flex items-center gap-2">
										<Tooltip title="Set time range">
											<IconButton
												onClick={() => setOpen(true)}
												// sx={{ color: textColor }}
											>
												<Timer />
											</IconButton>
										</Tooltip>
										<div>
											<Chip
												icon={<PlayArrow />}
												label={`Start: ${formatDate(
													form.start_time
												)}`}
												color={
													form.start_time
														? "primary"
														: "default"
												}
												variant="outlined"
												sx={{
													bgcolor: "background.paper",
												}}
											/>
										</div>
									</div>
									<div className="flex items-center gap-2">
										<div>
											<Chip
												icon={<Stop />}
												label={`End: ${formatDate(
													form.end_time
												)}`}
												color={
													form.end_time
														? "primary"
														: "default"
												}
												variant="outlined"
												sx={{
													bgcolor: "background.paper",
												}}
											/>
										</div>
									</div>
								</div>
							)} */}

							{form.is_published && (
								<div className="w-full flex flex-col gap-4 justify-center max-w-sm mx-auto">
									<QuizSchedule
										form={form}
										setForm={setForm}
										currentTime={currentTime}
									/>
								</div>
							)}
						</Box>
					</CardContent>
				</Card>
				{form.submissions.length > 0 && (
					<SubmissionTable
						submissions={form.submissions}
						questions={form.questions}
					/>
				)}
				<WinnerTable form={form} setForm={setForm} />
			</div>
		</LocalizationProvider>
	);
}
