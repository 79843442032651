import { useEffect, useState } from "react";
import CardContainer from "../../components/Containers/CardContainer";
import quizApi from "../../api/quiz";
import {
	Card,
	CardMedia,
	CardContent,
	Typography,
	CardActions,
	Button,
	Chip,
	Box,
} from "@mui/material";
import { Edit, Delete, AssignmentTurnedIn, Error } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import ScheduleIcon from "@mui/icons-material/Schedule";
import BlockIcon from "@mui/icons-material/Block";
import Pluralize from "pluralize";

export default function FormsGrid() {
	const [currentTime, setCurrentTime] = useState(null);
	const [data, setData] = useState(null);
	const navigate = useNavigate();
	const fetchData = async () => {
		const response = await quizApi.getAllQuizes();
		if (response.success) {
			console.log(response.data);
			setData(response.data);
			setCurrentTime(new Date(response.time));
		}
	};
	useEffect(() => {
		fetchData();
	}, []);

	const getStatusIcon = (quiz) => {
		if (!quiz.is_published) {
			return <BlockIcon />;
		} else if (!quiz.start_time) {
			return <Error />;
		} else if (currentTime < new Date(quiz.start_time)) {
			return <ScheduleIcon />;
		} else if (quiz.end_time && currentTime > new Date(quiz.end_time)) {
			return <EventBusyIcon />;
		} else {
			return <PlayCircleFilledIcon />;
		}
	};

	const getStatusText = (quiz) => {
		if (!quiz.is_published) {
			return "Unpublished";
		} else if (!quiz.start_time) {
			return "No Start Time Specified";
		} else if (currentTime < new Date(quiz.start_time)) {
			return "Upcoming";
		} else if (quiz.end_time && currentTime > new Date(quiz.end_time)) {
			return "Ended";
		} else {
			return "Running";
		}
	};

	return (
		<CardContainer col={3}>
			{data?.map((quiz) => (
				<Card
					sx={{
						maxWidth: 345,
						height: "100%",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						{/* <Chip
							label={
								quiz.is_published ? "Published" : "Unpublished"
							}
							size="small"
							color={quiz.is_published ? "success" : "default"}
							variant="outlined"
						/>
						<Chip
							label={
								currentTime < new Date(quiz.start_time)
									? "Upcoming"
									: quiz.end_time &&
									  currentTime > new Date(quiz.end_time)
									? "Ended"
									: "Running"
							} // Assuming quiz.status holds "Running", "Upcoming", or "Ended"
							size="small"
							color={
								currentTime < new Date(quiz.start_time)
									? "warning"
									: quiz.end_time &&
									  currentTime > new Date(quiz.end_time)
									? "default"
									: "success"
							}
							variant="outlined"
						/> */}
						<Typography
							variant="body2"
							color={
								!quiz.is_published
									? "textSecondary"
									: !quiz.start_time
									? "background"
									: currentTime < new Date(quiz.start_time)
									? "warning"
									: quiz.end_time &&
									  currentTime > new Date(quiz.end_time)
									? "background"
									: "primary"
							}
							align="center"
							className="w-full p-2 flex items-center gap-2"
						>
							{getStatusIcon(quiz)} {getStatusText(quiz)}
						</Typography>
					</Box>
					<CardMedia
						component="img"
						image={
							quiz.thumbnail ||
							"https://via.placeholder.com/300x140?text=No+Thumbnail"
						}
						alt={quiz.title}
						sx={{
							objectFit: "cover",
							width: "100%",
							height: "140px",
						}}
					/>
					<CardContent sx={{ flexGrow: 1 }}>
						<Typography
							// gutterBottom
							variant="h5"
							component="div"
							noWrap
						>
							{quiz.title}
						</Typography>
						<Box
							sx={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								mt: 1,
							}}
						>
							<Chip
								icon={<AssignmentTurnedIn />}
								label={
									quiz.start_time &&
									currentTime > new Date(quiz.start_time)
										? Pluralize(
												"Submission",
												quiz.submissions.length ?? 0,
												true
										  )
										: Pluralize(
												"Question",
												quiz.questions.length ?? 0,
												true
										  )
								}
								size="small"
								color="primary"
								variant="outlined"
							/>
							<Typography variant="body2" color="text.secondary">
								{new Date(quiz.created_at).toLocaleDateString()}
							</Typography>
						</Box>
					</CardContent>
					<CardActions sx={{ justifyContent: "flex-end" }}>
						<Button
							size="small"
							startIcon={<Edit />}
							onClick={() =>
								navigate("/priyo-play/edit#" + quiz.id)
							}
						>
							Edit
						</Button>
						{/* <Button
							size="small"
							color="error"
							startIcon={<Delete />}
						>
							Delete
						</Button> */}
					</CardActions>
				</Card>
			))}
		</CardContainer>
	);
}
