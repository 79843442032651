import React, { useEffect, useState } from "react";
import GenericForm from "../../components/Forms/GenericForm";
import profileApi from "../../api/profile";
import { showError, showSuccess } from "../../App";
import { useLocation } from "react-router-dom";
import GenericPreview from "../../components/Preview/GenericPreview";

export default function PreviewProfile() {
	// This could be fetched from your API
	const [profile, setProfile] = useState(null);
	const [schema, setSchema] = useState(null);
	const location = useLocation();
	const [id, setId] = useState(null);
	useEffect(() => {
		setId(location.hash.substring(1));
	}, [location]);

	const handleSubmit = async (formData) => {
		const res = await profileApi.updateProfile(id, formData);
		if (res.success) {
			showSuccess("Changes saved successfully");
		} else {
			showError("Can't save changes");
		}
	};

	const fetchProfile = async () => {
		const res = await profileApi.getProfileById(id);
		console.log(res);
		if (res.success) {
			setProfile(res.data);
		} else {
			showError("Can't fetch profile");
		}
	};

	const fetchSchema = async () => {
		const res = await profileApi.getProfileSchema();
		if (res.success) {
			setSchema(res.data);
		} else {
			showError("Can't fetch schema");
		}
	};

	useEffect(() => {
		if (id) {
			fetchProfile();
		}
	}, [id]);

	useEffect(() => {
		fetchSchema();
	}, []);

	return (
		profile &&
		schema && (
			<GenericPreview
				initialData={profile}
				// onSubmit={handleSubmit}
				schema={schema}
				title="View Profile"
			/>
		)
	);
}
