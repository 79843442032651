import Api from "./base";

class NewsApi extends Api {
	getAllNews = async () => {
		return await this.get("/news/");
	};
	getNewsById = async (id) => {
		return await this.get(`/news/${id}/`);
	};
	getNewsSchema = async () => {
		return await this.get("/news/schema/");
	};
	updateNews = async (id, data) => {
		return await this.put(`/news/${id}/`, data);
	};
	deleteNews = async (id) => {
		return await this.delete(`/news/${id}/`);
	};
	createNews = async (data) => {
		console.log("Sending: ", data);
		return await this.post("/news/", data);
	};
}

const newsApi = new NewsApi();
export default newsApi;
