import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
	Button,
	Card,
	CardActionArea,
	CardContent,
	Chip,
	Skeleton,
	TableHead,
	Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEye,
	faPenToSquare,
	faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { Add, Delete, Edit, Preview } from "@mui/icons-material";
import Confirmation from "../../components/Cards/Confirmation";
import CustomTablePaginationActions from "../../components/Tables/TablePaginationActions";
import trainerApi from "../../api/trainer";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { showError, showSuccess } from "../../App";
import storageApi from "../../api/storage";
import { getTimeStamp } from "../../services/dateUtils";
const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:last-child td, &:last-child th": {
		border: 0,
	},
}));

function createData(id, source, count, uploaded_at, file_name) {
	return { id, source, count, uploaded_at: uploaded_at, file_name };
}
export default function ExcelTable() {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [rows, setRows] = useState([]);
	const [open, setOpen] = useState(false);
	const navigate = useNavigate();
	const [selected, setSelected] = useState({});
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	// State to store random widths
	const [randomWidths, setRandomWidths] = useState([]);

	useEffect(() => {
		// Generate 5 random widths when the component mounts
		const widths = Array.from(
			{ length: 5 },
			() => `${Math.floor(Math.random() * (90 - 10 + 1)) + 10}%`
		);
		setRandomWidths(widths);
	}, []);

	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	useEffect(() => {
		if (!data) return;
		setRows(
			data
				.map((item) =>
					createData(
						item.id,
						item.data_source,
						item.user_count,
						item.uploaded_at,
						item.file_name
					)
				)
				.sort(
					(a, b) => new Date(b.uploaded_at) - new Date(a.uploaded_at)
				)
		);
		setLoading(false);
	}, [data]);

	const fetchData = async () => {
		const response = await storageApi.getUploadedData();
		if (response.success) {
			console.log(response.data);
			setData(response.data);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const handleDelete = async (row) => {
		// const res = await trainerApi.deleteQuestionById(row.id);
		// if (res.success) {
		// 	showSuccess("Successfully deleted.");
		// } else {
		// 	// Revert the changes
		// 	setData((prev) => [...prev, row]);
		// 	showError("Failed to delete.");
		// }
	};
	return (
		<div className="w-full max-w-3xl">
			<Confirmation
				open={open}
				setOpen={setOpen}
				onConfirm={() => {
					const newData = data.filter(
						(item) => item.id !== selected.id
					);
					setData(newData);
					handleDelete(selected);
					setSelected(null);
				}}
			/>
			{loading ? (
				<TableContainer component={Paper}>
					<Table aria-label="custom pagination table">
						<TableHead>
							<TableRow>
								<TableCell className="!py-3">
									<div className="flex flex-row gap-4 justify-end">
										<Skeleton
											variant="text"
											height={30}
											className="w-40"
										/>
										<Skeleton
											variant="text"
											height={30}
											className="w-24"
										/>
										<Skeleton
											variant="text"
											// width={"10rem"}
											height={30}
											className="w-12 md:w-32"
										/>
									</div>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{randomWidths.map((rWidth, index) => (
								<StyledTableRow
									key={index}
									className="font-sans text-base"
								>
									<TableCell className="min-w-[1rem] font-sans text-base">
										<div className="flex flex-col md:flex-row gap-2">
											<div className="flex flex-col md:flex-row gap-4 w-full">
												<Skeleton
													variant="text"
													width="40%"
													height={30}
												/>
												<div className="flex flex-row w-full md:w-[60%] justify-between gap-4">
													<Skeleton
														variant="text"
														width={"50%"}
														height={30}
														// className="md:hidden"
													/>
													<Skeleton
														variant="text"
														width={"50%"}
														height={30}
														// className="md:hidden"
													/>
												</div>
											</div>
										</div>
									</TableCell>
								</StyledTableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			) : (
				<TableContainer component={Paper}>
					<Table aria-label="custom pagination table">
						<TableBody>
							{(rowsPerPage > 0
								? rows.slice(
										page * rowsPerPage,
										page * rowsPerPage + rowsPerPage
								  )
								: rows
							).map((row) => (
								<StyledTableRow
									key={row.id}
									className="font-sans text-base"
								>
									<TableCell
										// sx={{ width: 20 }}
										// component="th"
										// scope="row"
										className="min-w-[1rem] font-sans text-base"
									>
										<div className="flex flex-col md:flex-row gap-2 md:items-center">
											<div className="flex flex-row gap-5 w-full md:w-[40%]">
												<Typography>
													{row.source}
												</Typography>
												<Chip
													label={
														row.count + " entries"
													}
													color="primary"
													size="small"
												/>
											</div>
											<div className="flex flex-row w-full md:w-[60%] justify-between">
												<div className="w-1/2">
													<Typography className="text-sm">
														{row.file_name}
													</Typography>
												</div>
												<div className="w-1/2">
													<Typography className="text-sm text-right">
														{getTimeStamp(
															row.uploaded_at
														)}
													</Typography>
												</div>
											</div>
										</div>
									</TableCell>
								</StyledTableRow>
							))}
							{/* {emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={6} />
							</TableRow>
						)} */}
						</TableBody>
						<TableHead>
							<TableRow>
								<TablePagination
									rowsPerPageOptions={[
										5,
										10,
										25,
										{ label: "All", value: -1 },
									]}
									colSpan={3}
									count={rows.length}
									rowsPerPage={rowsPerPage}
									page={page}
									slotProps={{
										select: {
											inputProps: {
												"aria-label": "rows per page",
											},
											native: true,
										},
									}}
									onPageChange={handleChangePage}
									onRowsPerPageChange={
										handleChangeRowsPerPage
									}
									ActionsComponent={
										CustomTablePaginationActions
									}
								/>
							</TableRow>
						</TableHead>
					</Table>
				</TableContainer>
			)}
		</div>
	);
}
